import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/styles";
import ActionButton from "./ActionButton";

const useStyles = makeStyles(theme => ({
    reject: {
        backgroundColor: theme.palette.color.red,
        color: '#ffffff',
        '&:hover': {
            backgroundColor: theme.palette.color.darkRed,
        },
    }
}));

const RejectButton = ({isLoading, onClick}) => {
    const classes = useStyles();

    return <Tooltip title='Отчет будет удален без возможности восстановления.'>
        <div>
            <ActionButton
                isLoading={isLoading}
                className={classes.reject}
                onClick={onClick}
            >
                Удалить
            </ActionButton>
        </div>
    </Tooltip>
};

export default RejectButton;
