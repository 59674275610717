import React from 'react';
import {Route as ReactRoute} from 'react-router-dom';

/**
 * Это обертка на Route из пакета 'react-router-dom'
 *
 * Суть обертки в том, что при переходе на url
 * она заставляет React заменить экземпляр компонента,
 * который был ранее отрендерен для этого url,
 * на абсолютно новый.
 *
 * Обертка была создана, для борьбы со следующим багом:
 *
 * Если кликнуть на пукт меню "Новый отчет" и частично заполнить форму,
 * потом кликнуть на пункт меню "Новый отчет"
 * форма не сбрасывается из-за оптимизаций react,
 * так как для react ничего не изменилось и он оставляет старый экземпляр компонента.
 *
 * Чтобы заставить react заменить старый экземпляр компонента на новый,
 * мы меняем prop key для компонента.
 */

let key = 0;

const Route = (props) => <ReactRoute {...props} key={key++} />;

export default Route;