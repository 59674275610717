import React from 'react';
import Grid from "@material-ui/core/Grid";
import AdditionalFile from './AdditionalFile';

const AdditionalFiles = ({files, fileType, emptyText = ''}) => {
    const filesToDisplay = files.filter(file => file.type === fileType);

    if(filesToDisplay.length === 0) {
        return emptyText;
    }

    return <Grid container direction='column' spacing={1}>
        {filesToDisplay.map(file => <Grid item key={file.id}>
            <AdditionalFile file={file}/>
        </Grid>)}
    </Grid>
};

export default AdditionalFiles;