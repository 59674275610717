import React, {useContext} from "react";
import NumberFormat from "react-number-format";
import {CurrencyContext} from "../../../hooks/useCurrency";

const MoneyField = ({value, ...rest}) => {

    const {symbol} = useContext(CurrencyContext);

    return <NumberFormat
        value={value}
        thousandSeparator={' '}
        prefix={`${symbol} `}
        {...rest}
    />
};

export default MoneyField;