import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import AdditionalFile from './AdditionalFile';
import {times} from "lodash";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    file: {
        flexGrow: 1,
        flexBasis: 250,
        minWidth: 250,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    hidden: {
        height: 0,
        margin: 0
    }
}));

const FileCard = ({hidden = false, children}) => {
    const classes = useStyles();

    return <Grid item xs={2} className={clsx(classes.file, hidden && classes.hidden)}>
        {children}
    </Grid>
}

// flexbox растягивает все элементы в одной строке на всю доступную ширину
// если при этом в разных рядах разное кол-во элементов, то ширина элементов будет своей в каждом ряду
// чтобы вместо этого все элементы были одной ширины (тогда должно остаться свободное место, не занятое элементами)
// нужно добавить искусственно элементы в последний ряд, чтобы они занимали место
// при этом эти элементы должны быть невидимыми
//
// подробнее и с картинками: https://medium.com/developedbyjohn/equal-width-flex-items-a5ba1bfacb77
const placeholders = times(10, (index) => <FileCard hidden={true} key={`placeholder-${index}`} />);

const AdditionalFiles = ({files, fileType, emptyText = ''}) => {
    const filesToDisplay = files.filter(file => file.type === fileType);

    if (filesToDisplay.length === 0) {
        return emptyText;
    }

    return <Grid container direction='row'>
        {filesToDisplay.map(file =>
            <FileCard key={file.id}>
                <AdditionalFile file={file}/>
            </FileCard>
        )}
        {placeholders}
    </Grid>
};

export default AdditionalFiles;