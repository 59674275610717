import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Navigation from "./Navigation";
import MobileNavigation from './mobile/Navigation';
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles({
    background: {
        minHeight: '100vh',
    },
    body: {
        minHeight: '100vh'
    },
});

const Layout = ({children}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return <div className={classes.background}>
        {isMobile
            ? <MobileNavigation/>
            : <Navigation/>
        }
        {children}
    </div>
};

export default Layout;