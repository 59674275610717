import React, {useState, useEffect} from 'react';
import {useSnackbar} from "../../../hooks/useSnackbar";
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EmptyFileUploader from './status/EmptyFileUploader';
import LoadingFileUploader from './status/LoadingFileUploader';
import FilledFileUploader from './status/FilledFileUploader';

import {EMPTY, SUCCESS, WARNING, ERROR, LOADING} from './status/uploaderStatuses';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    }
}));

const FileSelector = ({label, onUpload, setStepPassed}) => {
    const classes = useStyles();
    const {enqueueSuccess, enqueueWarning, enqueueError} = useSnackbar();

    const [status, setStatus] = useState(EMPTY);
    const [fileName, setFileName] = useState();

    const isStepPassed = status === SUCCESS;

    useEffect(() => setStepPassed(isStepPassed), [isStepPassed, setStepPassed]);

    const onFileSelected = (file) => {
        setFileName(file.name);
        setStatus(LOADING);

        onUpload(file)
            .then(() => {
                setStatus(SUCCESS);
                enqueueSuccess(`${label}: Файл успешно загружен`);
            })
            .catch(error => {
                console.log(error);
                if (error.status === 422) {
                    setStatus(WARNING);
                    enqueueWarning(`${label}: ${error.body}`);
                } else {
                    setStatus(ERROR);
                    enqueueError(`${label}: ${error.body}`);
                }
            });
    };

    const renderFileUploader = () => {
        switch (status) {
            case EMPTY:
                return <EmptyFileUploader onFileSelected={onFileSelected} id={label}/>;
            case LOADING:
                return <LoadingFileUploader
                    onFileSelected={onFileSelected}
                    id={label}
                    fileName={fileName}
                />;
            case SUCCESS:
            case WARNING:
            case ERROR:
                return <FilledFileUploader
                    onFileSelected={onFileSelected}
                    id={label}
                    fileName={fileName}
                    status={status}
                />;
            default:
                throw new Error(`Unknown status ${status}`);
        }
    };

    return <Grid container direction='column'>
        <Grid item className={classes.item}>
            <Typography variant='h5'>{label}</Typography>
        </Grid>
        <Grid item className={classes.item}>
            {renderFileUploader()}
        </Grid>
    </Grid>
};

export default FileSelector;
