import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        minHeight: '100vh',
    },
    body: {
        padding: theme.spacing(2),
    },
}));

const Layout = ({children}) => {
    const classes = useStyles();

    return <div className={classes.paper}>
        <div className={classes.body}>
            {children}
        </div>
    </div>
};

export default Layout;