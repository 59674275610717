import React, {useCallback, useMemo} from 'react';
import {useSnackbar as useNotistackSnackbar} from 'notistack';
import {SnackbarProvider as NotistackSnackbarProvider} from 'notistack';
import Button from "@material-ui/core/Button";

export const SnackbarProvider = ({children}) => {
    const notistackRef = React.createRef();
    const onDismissSnackbar = key => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return <NotistackSnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={(key) => (
            <Button onClick={onDismissSnackbar(key)} style={{color: 'white'}}>
                Ок
            </Button>
        )}
    >{children}</NotistackSnackbarProvider>
};

export const useSnackbar = () => {
    const {enqueueSnackbar} = useNotistackSnackbar();

    const enqueueSuccess = useCallback(
        (message) => enqueueSnackbar(message, {variant: 'success', autoHideDuration: 2000}),
        [enqueueSnackbar]
    );

    const enqueueWarning = useCallback(
        (message) => enqueueSnackbar(message, {variant: 'warning', persist: true}),
        [enqueueSnackbar]
    );

    const enqueueError = useCallback(
        (message) => enqueueSnackbar(message, {variant: 'error', persist: true}),
        [enqueueSnackbar]
    );

    return useMemo(
        () => ({enqueueSuccess, enqueueWarning, enqueueError}),
        [enqueueSuccess, enqueueWarning, enqueueError]
    );
};


