/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';


/**
* Enum class FileType.
* @enum {}
* @readonly
*/
export default class FileType {
    
        /**
         * value: "introduction"
         * @const
         */
        introduction = "introduction";

    
        /**
         * value: "reportCommentary"
         * @const
         */
        reportCommentary = "reportCommentary";

    
        /**
         * value: "reportSummary"
         * @const
         */
        reportSummary = "reportSummary";

    
        /**
         * value: "reportInterpretation"
         * @const
         */
        reportInterpretation = "reportInterpretation";

    
        /**
         * value: "netProfit"
         * @const
         */
        netProfit = "netProfit";

    
        /**
         * value: "revenue"
         * @const
         */
        revenue = "revenue";

    
        /**
         * value: "productIncomeForPeriod"
         * @const
         */
        productIncomeForPeriod = "productIncomeForPeriod";

    
        /**
         * value: "productIncomeForLastMonth"
         * @const
         */
        productIncomeForLastMonth = "productIncomeForLastMonth";

    
        /**
         * value: "salary"
         * @const
         */
        salary = "salary";

    
        /**
         * value: "expensesForPeriod"
         * @const
         */
        expensesForPeriod = "expensesForPeriod";

    
        /**
         * value: "expensesForLastMonth"
         * @const
         */
        expensesForLastMonth = "expensesForLastMonth";

    

    /**
    * Returns a <code>FileType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/FileType} The enum <code>FileType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}


