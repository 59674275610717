import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/styles";
import ActionButton from "./ActionButton";

const useStyles = makeStyles(theme => ({
    save: {
        color: '#ffffff'
    }
}));

const SaveButton = ({isLoading, onClick}) => {
    const classes = useStyles();

    return <Tooltip title='Данные будут сохранены. Отчет будет доступен для редактирования и дальнейшей публикации.'>
        <div>
            <ActionButton
                isLoading={isLoading}
                color='primary'
                className={classes.save}
                onClick={onClick}
            >
                Сохранить
            </ActionButton>
        </div>
    </Tooltip>
};

export default SaveButton;
