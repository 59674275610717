import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import FileUploader from './FileUploader';

const useStyles = makeStyles(theme => ({
    empty: {
        borderStyle: 'dashed',
        borderColor: theme.palette.color.grey
    }
}));

const EmptyFileUploader = ({onFileSelected, id}) => {
    const classes = useStyles();

    return <FileUploader onFileSelected={onFileSelected} id={id} cssClass={classes.empty}>
        <Grid item>
            <Add fontSize='large'/>
        </Grid>
        <Grid item>
            <Typography variant='subtitle2'>xlsx</Typography>
        </Grid>
    </FileUploader>
};

export default EmptyFileUploader;