import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    card: {
        padding: theme.spacing(2),
        height: '100%',
        width: '100%'
    },
    link: {
        textDecoration: 'none',
        width: '100%'
    },
    fileIcon: {
        color: theme.palette.color.darkBlue,
        fontSize: '3em'
    },
    text: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        color: 'black'
    },
    row: {
        textAlign: 'center',
        padding: theme.spacing(0.5)
    },
    container: {
        height: '100%',
        width: '100%'
    }
}));

const ReportDownloader = ({link, name, onDownloadedFile}) => {
    const classes = useStyles();

    return <Grid container className={classes.container}>
        <Typography variant='h6' className={classes.text}>Отчет</Typography>
        <Card className={classes.card}>
            <Grid container justify='space-between' alignItems='center'>
                <Grid item container xs={12} alignItems={'center'}>
                    <Grid item xs={3}>
                        <FileIcon className={classes.fileIcon}/>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{name}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction='row' justify='center' alignItems={'center'}>
                        <Grid item xs={12} className={classes.row}>
                            <a href={link} className={classes.link} download={name} id='download-report'
                               onClick={onDownloadedFile}>
                                <Button color='primary' variant='outlined' fullWidth>Скачать</Button>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    </Grid>
};

export default ReportDownloader;