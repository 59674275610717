import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";

const TextCell = ({name, value}) => {
    return <TableCell>
        <Grid container direction='row' justify='space-between' alignItems='center'>
            <Grid item>
                <Typography variant='body2'>{name}</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body2'>{value}</Typography>
            </Grid>
        </Grid>
    </TableCell>
};

export default TextCell;