import React from "react";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
    },
    commentary: {
        marginTop: theme.spacing(3),
    }
}));

const ChartContainer = ({chartName, label, period, children, renderComment}) => {
    const classes = useStyles();

    return <Grid item xs={12}>
        <Card className={classes.item}>
            <Grid container direction='row' justify='space-evenly'>
                <Grid item xs={4}>
                    <Grid container direction='column'>
                        <Grid item>
                            <Typography variant='h5'>{label}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle1'>{period}</Typography>
                        </Grid>
                        <Grid item className={classes.commentary}>
                            {renderComment(chartName)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    {children}
                </Grid>
            </Grid>
        </Card>
    </Grid>
};

export default ChartContainer;