/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import Period from './Period';





/**
* The PreviousPeriodData model module.
* @module model/PreviousPeriodData
* @version 1.0.0
*/
export default class PreviousPeriodData {
    /**
    * Constructs a new <code>PreviousPeriodData</code>.
    * @alias module:model/PreviousPeriodData
    * @class
    * @param period {module:model/Period} 
    * @param revenue {Number} 
    * @param expenses {Number} 
    * @param netProfit {Number} 
    */

    constructor(period, revenue, expenses, netProfit) {
        

        
        

        this['period'] = period;this['revenue'] = revenue;this['expenses'] = expenses;this['netProfit'] = netProfit;

        
    }

    /**
    * Constructs a <code>PreviousPeriodData</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PreviousPeriodData} obj Optional instance to populate.
    * @return {module:model/PreviousPeriodData} The populated <code>PreviousPeriodData</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PreviousPeriodData();

            
            
            

            if (data.hasOwnProperty('period')) {
                obj['period'] = Period.constructFromObject(data['period']);
            }
            if (data.hasOwnProperty('revenue')) {
                obj['revenue'] = ApiClient.convertToType(data['revenue'], 'Number');
            }
            if (data.hasOwnProperty('expenses')) {
                obj['expenses'] = ApiClient.convertToType(data['expenses'], 'Number');
            }
            if (data.hasOwnProperty('netProfit')) {
                obj['netProfit'] = ApiClient.convertToType(data['netProfit'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {module:model/Period} period
    */
    period = undefined;
    /**
    * @member {Number} revenue
    */
    revenue = undefined;
    /**
    * @member {Number} expenses
    */
    expenses = undefined;
    /**
    * @member {Number} netProfit
    */
    netProfit = undefined;








}


