import React from 'react';
import {useLocation} from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {toLogin, toReportCreator, toMain, toReports, toHistory} from "../../helpers/go";
import {useAuthContext} from '../../hooks/useAuthentication';
import {useAlertContext} from './Alert';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(3),
        fontWeight: 'bold'
    },
    highlighted: {
        color: theme.palette.color.blue
    }
}));

const Navigation = () => {
    const classes = useStyles();

    const {logOut, hasRole} = useAuthContext();
    const {pathname} = useLocation();
    const {openAlert} = useAlertContext();

    const isCreator = pathname.startsWith('/creator');
    const isEditor = pathname.startsWith('/creator/edit') || pathname.startsWith('/reports/edit');
    const isReports = pathname.startsWith('/reports');
    const isHistory = pathname.startsWith('/history');
    const isMain = !isCreator && !isReports && !isHistory;
    const showAlert = isCreator || isEditor;

    const withAlert = (redirect) => {
        if (showAlert) {
            openAlert('Вы уверены, что хотите покинуть страницу? Несохраненные данные будут утеряны!', redirect);
        }
        else {
            redirect();
        }
    };

    const goToMain = () => withAlert(toMain);
    const goToNewReport = () => withAlert(toReportCreator);
    const goToReports = () => withAlert(toReports);
    const goToHistory = () => withAlert(toHistory);
    const onLogOut = () => withAlert(() => logOut().then(toLogin));

    return <AppBar position='sticky' color='default'>
        <Toolbar>
            <Grid container direction='row' justify='space-between'>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <Button
                                className={clsx(classes.button, isReports && classes.highlighted)}
                                onClick={goToReports}>
                                Отчеты
                            </Button>
                        </Grid>
                        {(hasRole('ROLE_ACCOUNTANT') || hasRole('ROLE_ADMIN')) && <>
                            <Grid item>
                                <Button
                                    className={clsx(classes.button, isHistory && classes.highlighted)}
                                    onClick={goToHistory}>
                                    История
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={goToNewReport}
                                    className={clsx(classes.button, isCreator && classes.highlighted)}
                                >
                                    Новый отчет
                                </Button>
                            </Grid>
                        </>}
                        <Grid item>
                            <Button
                                onClick={goToMain}
                                className={clsx(classes.button, isMain && classes.highlighted)}
                            >
                                Главная страница
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.button}>
                    <Button onClick={onLogOut}>Выйти</Button>
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>
};

export default Navigation;