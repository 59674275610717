import React from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/styles/makeStyles";
import {formatDateTime, formatDateToMonthYear} from "../../helpers/dateHelper";
import {downloadReportLink, toReportsEdit, toReportsView} from "../../helpers/go";
import Status from './Status';
import {Typography} from "@material-ui/core";
import FileDownloader from "../common/FileDownloader";
import Link from '@material-ui/core/Link';
import RichTextRenderer from '../common/RichTextRenderer'
import AdditionalFiles from "./AdditionalFiles";

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
    },
    item: {
        padding: theme.spacing(0.5),
    },
    icon: {
        marginRight: '0.5em',
    },
    card: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
    },
    header: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    text: {
        paddingBottom: theme.spacing(1)
    },
    link: {
        '&:hover': {
            cursor: 'pointer',
        },
    }
}));


const Report = ({report: {id, name, status, date, additionalFiles, period, reportCommentary}}) => {
    const classes = useStyles();

    const reportLink = downloadReportLink(id);
    const goToReportsView = () => toReportsView(id);
    const goToReportsEdit = () => toReportsEdit(id);

    const renderLink = () => {
        if (status === 'APPROVED') {
            return <Link className={classes.link} onClick={goToReportsView}>Просмотр</Link>
        } else if (status === 'IN_PROGRESS') {
            return <Link className={classes.link} onClick={goToReportsEdit}>Редактирование</Link>
        }
    };
    return <Grid container className={classes.container}>
        <Grid item className={classes.item} xs={12}>
            <Typography>{formatDateTime(date)}</Typography>
        </Grid>
        <Grid item className={classes.item} xs={12}>
            <Grid container>
                <Grid item xs className={classes.item}>
                    <FileDownloader link={reportLink} name={name}/>
                </Grid>
                <Grid item xs className={classes.item}>
                    <Card className={classes.card}>
                        <Typography className={classes.text}>Валюта</Typography>
                        <Typography>$ USD</Typography>
                    </Card>
                </Grid>
                <Grid item xs className={classes.item}>
                    <Card className={classes.card}>
                        <Typography className={classes.text}>Статус</Typography>
                        <Status status={status}/>
                    </Card>
                </Grid>
                <Grid item xs className={classes.item}>
                    <Card className={classes.card}>
                        <Typography className={classes.text}>
                            {formatDateToMonthYear(period.start)} - {formatDateToMonthYear(period.end)}
                        </Typography>
                        {renderLink()}
                    </Card>
                </Grid>
            </Grid>
        </Grid>
        {additionalFiles &&
            <Grid item className={classes.item} xs={12}>
                <Grid item xs className={classes.item}>
                    <AdditionalFiles files={additionalFiles} fileType={'reportInterpretation'} />
                </Grid>
            </Grid>
        }
        <Grid item className={classes.item} xs={12}>
            <Card className={classes.card}>
                <RichTextRenderer text={reportCommentary} />
            </Card>
        </Grid>
    </Grid>
};

export default Report;