import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {NewFile} from "../../../services/Files";
import UnsavedFile from "./UnsavedFile";
import SavedFile from "./SavedFile";
import FileUploader from "./FileUploader";
import {FieldArray} from "formik";
import clsx from "clsx";
import {times} from "lodash";
import {useAlertContext} from "../../common/Alert";

const useStyles = makeStyles(theme => ({
    file: {
        flexGrow: 1,
        flexBasis: 250,
        minWidth: 250,
    },
    hidden: {
        height: 0,
        margin: 0
    }
}));

const FileCard = ({hidden = false, children}) => {
    const classes = useStyles();

    return <Grid item className={clsx(classes.file, hidden && classes.hidden)}>
        {children}
    </Grid>;
};

// flexbox растягивает все элементы в одной строке на всю доступную ширину
// если при этом в разных рядах разное кол-во элементов, то ширина элементов будет своей в каждом ряду
// чтобы вместо этого все элементы были одной ширины (тогда должно остаться свободное место, не занятое элементами)
// нужно добавить искусственно элементы в последний ряд, чтобы они занимали место
// при этом эти элементы должны быть невидимыми
//
// подробнее и с картинками: https://medium.com/developedbyjohn/equal-width-flex-items-a5ba1bfacb77
const placeholders = times(10, (index) => <FileCard hidden={true} key={`placeholder-${index}`} />);

const Files = ({fileType, files, setFiles}) => {
    const {openAlert} = useAlertContext();

    const filesToDisplay = files.filter(file => file.type === fileType && !file.shouldBeDeleted);

    const addFile = (file) => setFiles([...files, new NewFile(file, fileType)]);

    const deleteFile = (file) => {
        file.delete();
        setFiles([...files]);
    };

    const deleteSavedFile = (file) => openAlert(
        <span>Вы уверены, что хотите <strong>удалить</strong> файл с сервера? После сохранения скачать файл будет невозможно!</span>,
        () => deleteFile(file)
    );

    const deleteUnsavedFile = (file) => openAlert(
        <span>Вы уверены, что хотите <strong>удалить</strong> файл?</span>,
        () => deleteFile(file)
    );

    const renderSavedFiles = () => {
        return filesToDisplay
            .filter(file => file.isSaved)
            .map(file =>
                <FileCard key={file.id}>
                    <SavedFile file={file} remove={deleteSavedFile} />
                </FileCard>
            );
    };

    const renderUnsavedFiles = () => {
        return filesToDisplay
            .filter(file => !file.isSaved)
            .map((file, fileIndex) =>
                <FileCard key={`file-${fileIndex}`}>
                    <UnsavedFile file={file} remove={deleteUnsavedFile} />
                </FileCard>
            );
    }


    return <Grid container direction='row' spacing={1}>
        {renderSavedFiles()}
        {renderUnsavedFiles()}

        <FileCard>
            <FileUploader name={fileType} onChange={addFile}/>
        </FileCard>

        {placeholders}
    </Grid>
};

const FormikAdapter = ({name, ...props}) => {
    return <FieldArray
        name={name}
        render={({form}) => <Files
            {...props}
            files={form.values[name]}
            setFiles={form.setFieldValue.bind(form, name)}
        />}
    />
}

export default FormikAdapter;