import React, {memo} from 'react';
import Grid from '@material-ui/core/Grid';
import ChartContainer from './ChartContainer';
import ProfitLineChart from './ProfitLineChart';
import RevenueLineChart from './RevenueLineChart';
import SalaryLineChart from './SalaryLineChart';
import BarChart from './BarChart';
import {formatDateToMonthYear} from "../../../helpers/dateHelper";
import {setColorsForNames} from "../chartFunctions";

const Charts = ({chartsData, period, renderComment}) => {
    const periodEnd = formatDateToMonthYear(period.end);
    const fullPeriod = `${formatDateToMonthYear(period.start)} - ${periodEnd}`;

    const colorsForNames = setColorsForNames(chartsData);

    return <Grid container>
        <ChartContainer
            label='Чистая прибыль'
            period={fullPeriod}
            commentary={renderComment('netProfit')}
        >
            <ProfitLineChart data={chartsData.netProfit} period={period}/>
        </ChartContainer>
        <ChartContainer
            label='Доходы, расходы, прибыль за период'
            period={fullPeriod}
            commentary={renderComment('revenue')}
        >
            <RevenueLineChart data={chartsData.revenue} period={period}/>
        </ChartContainer>
        <ChartContainer
            label='Доходы за период'
            period={fullPeriod}
            commentary={renderComment('productIncomeForPeriod')}
        >
            <BarChart data={chartsData.productIncomeForPeriod} colorsForNames={colorsForNames} period={period}/>
        </ChartContainer>
        <ChartContainer
            label='Доходы за истекший месяц'
            period={periodEnd}
            commentary={renderComment('productIncomeForLastMonth')}
        >
            <BarChart data={chartsData.productIncomeForLastMonth} colorsForNames={colorsForNames} period={period}/>
        </ChartContainer>
        <ChartContainer
            label='Возмещение расходов'
            period={fullPeriod}
            commentary={renderComment('salary')}
        >
            <SalaryLineChart data={chartsData.salary} period={period}/>
        </ChartContainer>
        <ChartContainer
            label='Расходы за период'
            period={fullPeriod}
            commentary={renderComment('expensesForPeriod')}
        >
            <BarChart data={chartsData.expensesForPeriod} colorsForNames={colorsForNames} period={period}/>
        </ChartContainer>
        <ChartContainer
            label='Расходы за истекший месяц'
            period={periodEnd}
            commentary={renderComment('expensesForLastMonth')}
        >
            <BarChart data={chartsData.expensesForLastMonth} colorsForNames={colorsForNames} period={period}/>
        </ChartContainer>
    </Grid>
};

export default memo(Charts);