import React, {memo} from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts';
import {calculateTrends, transformChartData, currencyFormatter, mobileCurrencyFormatter} from "../chartFunctions";
import LineChartWrapper from "./LineChartWrapper";

const SalaryLineChart = ({data, period}) => {

    const transformedData = transformChartData(data, period);
    const dataWithTrends = calculateTrends(transformedData, ['salary']);

    return <LineChartWrapper>
        <LineChart
            data={dataWithTrends}
            margin={{
                top: 5, right: 5, left: 5, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray='3 3'/>
            <XAxis dataKey='name'/>
            <YAxis tickFormatter={mobileCurrencyFormatter} mirror/>
            <Tooltip formatter={currencyFormatter}/>
            <Line name='Тренд возмещения расходов' dataKey='salaryTrend' stroke='#8ac8ff' dot={false} legendType='none' strokeWidth={4}/>
            <Line name='Прибыль' type='linear' dataKey='salary' stroke='#0085fa' legendType='square' strokeWidth={3}/>
        </LineChart>
    </LineChartWrapper>
};

export default memo(SalaryLineChart);
