import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

const CustomSwitch = ({checked, setChecked, leftTitle, rightTitle}) => {
    const handleChange = (event) => {
        setChecked(event.target.checked)
    };

    return <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>{leftTitle}</Grid>
            <Grid item>
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    color={'primary'}
                />
            </Grid>
            <Grid item>{rightTitle}</Grid>
        </Grid>
    </Typography>
};

export default CustomSwitch;