import React from 'react'
import ReactHtmlParser, {processNodes, convertNodeToElement} from "react-html-parser";
import Typography from "@material-ui/core/Typography";
import DOMPurify from 'dompurify';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    renderer: {
        wordBreak: 'break-word'
    },
});

const htmlTransformer = (node, index) => {
    switch (node.name) {
        case 'br':
            return null;
        case 'h2':
            return <Typography variant='h5' key={`h2-${index}`}><b>{processNodes(node.children, htmlTransformer)}</b></Typography>;
        case 'h3':
            return <Typography variant='h6' key={`h3-${index}`}><b>{processNodes(node.children, htmlTransformer)}</b></Typography>;
        case 'p':
            return <Typography key={`p-${index}`}>{processNodes(node.children, htmlTransformer)}</Typography>;
        default:
            return convertNodeToElement(node, index, htmlTransformer);
    }
};

const RichTextRenderer = ({text}) => {
    const classes = useStyles();

    return <div className={classes.renderer}>{ReactHtmlParser(DOMPurify.sanitize(text), {transform: htmlTransformer})}</div>;
};

export default RichTextRenderer;