import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    },
    card: {
        padding: theme.spacing(2),
    },
}));

const Currency = () => {
    const classes = useStyles();

    return <Grid container direction='column'>
        <Grid item className={classes.item}>
            <Typography variant='h6'>Валюта</Typography>
        </Grid>
        <Card className={classes.card}>
            <Grid item className={classes.item}>
                <TextField variant='outlined' disabled value='USD' fullWidth />
            </Grid>
        </Card>
    </Grid>
};

export default Currency;