import React from "react";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const TextCell = ({name, value}) => {
    return <TableRow>
        <TableCell>
            <Typography variant='body2'>{name}</Typography>
        </TableCell>
        <TableCell align='right'>
            <Typography variant='body2'>{value}</Typography>
        </TableCell>
    </TableRow>
};

export default TextCell;