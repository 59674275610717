/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The ChartsCommentary model module.
* @module model/ChartsCommentary
* @version 1.0.0
*/
export default class ChartsCommentary {
    /**
    * Constructs a new <code>ChartsCommentary</code>.
    * Комментарии к графикам
    * @alias module:model/ChartsCommentary
    * @class
    * @param netProfit {String} 
    * @param revenue {String} 
    * @param productIncomeForPeriod {String} 
    * @param productIncomeForLastMonth {String} 
    * @param salary {String} 
    * @param expensesForPeriod {String} 
    * @param expensesForLastMonth {String} 
    */

    constructor(netProfit, revenue, productIncomeForPeriod, productIncomeForLastMonth, salary, expensesForPeriod, expensesForLastMonth) {
        

        
        

        this['netProfit'] = netProfit;this['revenue'] = revenue;this['productIncomeForPeriod'] = productIncomeForPeriod;this['productIncomeForLastMonth'] = productIncomeForLastMonth;this['salary'] = salary;this['expensesForPeriod'] = expensesForPeriod;this['expensesForLastMonth'] = expensesForLastMonth;

        
    }

    /**
    * Constructs a <code>ChartsCommentary</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ChartsCommentary} obj Optional instance to populate.
    * @return {module:model/ChartsCommentary} The populated <code>ChartsCommentary</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChartsCommentary();

            
            
            

            if (data.hasOwnProperty('netProfit')) {
                obj['netProfit'] = ApiClient.convertToType(data['netProfit'], 'String');
            }
            if (data.hasOwnProperty('revenue')) {
                obj['revenue'] = ApiClient.convertToType(data['revenue'], 'String');
            }
            if (data.hasOwnProperty('productIncomeForPeriod')) {
                obj['productIncomeForPeriod'] = ApiClient.convertToType(data['productIncomeForPeriod'], 'String');
            }
            if (data.hasOwnProperty('productIncomeForLastMonth')) {
                obj['productIncomeForLastMonth'] = ApiClient.convertToType(data['productIncomeForLastMonth'], 'String');
            }
            if (data.hasOwnProperty('salary')) {
                obj['salary'] = ApiClient.convertToType(data['salary'], 'String');
            }
            if (data.hasOwnProperty('expensesForPeriod')) {
                obj['expensesForPeriod'] = ApiClient.convertToType(data['expensesForPeriod'], 'String');
            }
            if (data.hasOwnProperty('expensesForLastMonth')) {
                obj['expensesForLastMonth'] = ApiClient.convertToType(data['expensesForLastMonth'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} netProfit
    */
    netProfit = undefined;
    /**
    * @member {String} revenue
    */
    revenue = undefined;
    /**
    * @member {String} productIncomeForPeriod
    */
    productIncomeForPeriod = undefined;
    /**
    * @member {String} productIncomeForLastMonth
    */
    productIncomeForLastMonth = undefined;
    /**
    * @member {String} salary
    */
    salary = undefined;
    /**
    * @member {String} expensesForPeriod
    */
    expensesForPeriod = undefined;
    /**
    * @member {String} expensesForLastMonth
    */
    expensesForLastMonth = undefined;








}


