import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
    fileInput: {
        display: 'none'
    }
});

export default ({onFileSelected, accept, label, children, disabled = false}) => {
    const classes = useStyles();

    const onChange = (event) => {
        onFileSelected(event.target.files[0]);
        event.target.value = '';
    };

    return <>
        <label htmlFor={`input-${label}`}>
            <input
                className={classes.fileInput}
                accept={accept}
                type='file'
                id={`input-${label}`}
                onChange={onChange}
                disabled={disabled}
            />
            {children}
        </label>
    </>;
};
