/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The ReportName model module.
* @module model/ReportName
* @version 1.0.0
*/
export default class ReportName {
    /**
    * Constructs a new <code>ReportName</code>.
    * Имя отчета
    * @alias module:model/ReportName
    * @class
    * @param reportName {String} 
    */

    constructor(reportName) {
        

        
        

        this['reportName'] = reportName;

        
    }

    /**
    * Constructs a <code>ReportName</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ReportName} obj Optional instance to populate.
    * @return {module:model/ReportName} The populated <code>ReportName</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReportName();

            
            
            

            if (data.hasOwnProperty('reportName')) {
                obj['reportName'] = ApiClient.convertToType(data['reportName'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} reportName
    */
    reportName = undefined;








}


