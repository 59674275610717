import React, {useEffect, useState, memo, useCallback} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import {useSnackbar} from "../../../hooks/useSnackbar";
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {SUCCESS, ERROR, PENDING, WARNING} from './periodStauses';
import {createSpinningBorder} from '../../../themes/spinningBorder';
import MonthYearPicker from './MonthYearPicker';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
        width: '130px'
    },
    selector: {
        border: theme.border.width,
        borderRadius: theme.border.radius,
        borderStyle: 'solid',
    },
    [SUCCESS]: {
        borderColor: theme.palette.color.success
    },
    [WARNING]: {
        borderColor: theme.palette.color.warning
    },
    [ERROR]: {
        borderColor: theme.palette.color.error
    },
    [PENDING]: {
        ...createSpinningBorder(theme)
    }
}));

const PERIOD_DATE_FORMAT = 'MMMM YYYY';
const DATE_FORMAT = 'YYYY-MM-DD';

const getPeriodStart = (periodEnd) => {
    return moment(periodEnd, PERIOD_DATE_FORMAT).subtract(13, 'months').format(PERIOD_DATE_FORMAT);
};

const getPeriod = (periodEnd) => ({
    periodStart: getPeriodStart(periodEnd),
    periodEnd: periodEnd
});

const PeriodSelector = ({setReportPeriod, setStepPassed}) => {
    const classes = useStyles();
    const snack = useSnackbar();

    const [{periodStart, periodEnd}, setPeriod] = useState(getPeriod(moment()));
    const [status, setStatus] = useState();
    const isStepPassed = status === SUCCESS;

    useEffect(() => {
        const {enqueueSuccess, enqueueError, enqueueWarning} = snack;
        setStatus(PENDING);

        const reportPeriod = {
            start: moment(periodStart, PERIOD_DATE_FORMAT).format(DATE_FORMAT),
            end: periodEnd.startOf('month').format(DATE_FORMAT)
        };

        setReportPeriod(reportPeriod)
            .then(() => {
                setStatus(SUCCESS);
                enqueueSuccess('Период успешно добавлен');
            })
            .catch(error => {
                if (error.status === 422) {
                    setStatus(WARNING);
                    enqueueWarning(error.body);
                } else {
                    setStatus(ERROR);
                    enqueueError(error.body);
                }
            });
    }, [periodStart, periodEnd, setReportPeriod, snack]);

    useEffect(() => setStepPassed(isStepPassed), [isStepPassed, setStepPassed]);

    const onPeriodChanged = useCallback((value) => setPeriod(getPeriod(value)), [setPeriod]);

    return <Grid container direction='row' justify='center' alignItems='center'
                 className={clsx(classes[status], classes.selector)}>
        <Grid item className={classes.item}>
            <Typography>{periodStart}</Typography>
        </Grid>
        <Grid item>-</Grid>
        <Grid item className={classes.item}>
            <MonthYearPicker defaultDate={periodEnd} onChange={onPeriodChanged} />
        </Grid>
    </Grid>
};

export default memo(PeriodSelector);