import {linearRegressionLine, linearRegression} from 'simple-statistics';
import round from 'lodash/round';
import moment from 'moment';

export const calculateDataTotal = (data) => {
    let total = 0;
    for (let i=0; i < data.length; i++) {
        total = total + data[i].value;
    }
    return total;
};

export const calculatePercent = (dataTotal, itemValue) => {
    return itemValue * 100 / dataTotal;
};

export const normalizeProjectName = (projectName) => {
    const normalizedProjectName = projectName.replace(/^[\d]+(-|\. )/, '');
    return  normalizedProjectName.length > 16 ? normalizedProjectName.substring(0, 16) + ".." : normalizedProjectName;
};

export const extraColors = ['#5CA4A9', '#ED6A5A', '#F4F1BB', '#9BC1BC', '#263041', '#626072', '#A1A696', '#853230']

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FC5603', '#03F4FC', '#8803FC', '#FC03E3', ...extraColors];

export const keysForColors = ["09-PE - GI (RU), П", "09-PE, П", "13-GAFF, П", "17-LPS, П", "18-ISR, П",
    "21-SBC, П", "24-Nurista, П", "14-BO, П", "15-BCH, П", "16-IPR, П", "19-LMS, П", "20-PBR, П"];

export const testSetColors = {
    "09-PE - GI (RU), П": '#ffc000',
    "09-PE, П": '#99cc00',
    "13-GAFF, П": '#cfc',
    "17-LPS, П": '#9a9dde',
    "18-ISR, П": '#d0d2f0',
    "21-SBC, П": '#f48370',
    "24-Nurista, П": '#9d5568',
    "14-BO, П": '#0070c0'
};

export const setColors = {
    "00. НЕ УЧИТЫВАТЬ ПРИ ФОРМИРОВАНИИ ОТЧЕТА": '#ffc000',
    "01. PSP RU (SBC)": '#99cc00',
    "01. PSP RU (other)": '#cfc',
    "02. PSP": '#9a9dde',
    "03. Fintegrate": '#d0d2f0',
    "05. ISR": '#f48370',
    "06. LPS": '#9d5568',
    "99. Other": '#0070c0'
};

export const setColorsForNames = (chartsData) => {
    const includedDataTypes = ['productIncomeForPeriod', 'productIncomeForLastMonth', 'expensesForPeriod', 'expensesForLastMonth'];
    let names = [];
    for (let i=0; i < includedDataTypes.length; i++) {
        for (let j=0; j < chartsData[includedDataTypes[i]].length; j++) {
            if (!names.includes(chartsData[includedDataTypes[i]][j].name)) {
                names.push(chartsData[includedDataTypes[i]][j].name)
            }
        }
    }
    let colorsForNames = {};
    for (let i=0; i < names.length; i++) {
        const presetColor = setColors[names[i]];
        colorsForNames[names[i]] = presetColor ? presetColor :
          (COLORS[i] ? COLORS[i] : COLORS[i-COLORS.length-1]);
    }
    return colorsForNames;
};

export const transformChartData = (data, period) => data.map((item, index) => {
    return {
        name: moment(period.start).add(index, 'months').format('MMM YYYY'),
        ...item
    }
});

const numberFormatter = (number) => {
    if (Math.abs(number) > 99999) {
        return Math.sign(number) * ((Math.abs(number) / 1000000).toFixed(1)) + 'm';
    } else if (Math.abs(number) > 99) {
        return Math.sign(number) * ((Math.abs(number) / 1000).toFixed(1)) + 'k'
    } else {
        return Math.sign(number) * Math.abs(number)
    }
};

const NO_BREAK_SPACE = '\u00A0';

export const currencyFormatter = (value, symbol = '$') => `${symbol}${NO_BREAK_SPACE}${new Intl.NumberFormat('ru-RU').format(value)}`;

export const mobileCurrencyFormatter = value => numberFormatter(value);

/**
 * Рассчитывает тренд для переданных точек.
 *
 * На вход функция ожидает массив следующего формата:
 * [[0, 10], [1, 12], [2, 8]]
 * Первый элемент вложенных массивов - координата точки по оси X
 * Второй элемент вложенных массивов - координата точки по оси Y.
 *
 * Функция рассчитывает тренд для переданных точек и возвращает массив:
 * [[0, 10], [1, 10], [2, 10]]
 * Первый элемент вложенных массивов - координата тренда по оси X
 * Второй элемент вложенных массивов - координата тренда по оси Y.

 * @param       linePoints      Координаты точек.
 *
 * @returns     {*}             Координаты точек тренда.
 */
export const calculateTrend = (linePoints) => {
    const getTrendYPoint = linearRegressionLine(linearRegression(linePoints));
    return linePoints.map(([index, point]) => [index, round(getTrendYPoint(index), 2)]);
};

/**
 * Рассчитывает тренд для каждой переданной линии.
 *
 * Функция не изменит переданные в нее данные, а вернет новый массив.
 *
 * Набор данных должен иметь следующий формат:
 * [
 *  {xAxisName: 'May-2018',  column1: 10, column2: 5},
 *  {xAxisName: 'June-2018', column1: 8,  column2: 2},
 *  {xAxisName: 'July-2018', column1: 12, column2: 4},
 *  ...]
 *
 * Тогда список линий может выглядеть так:
 * [column1, column2]
 *
 * Набор данных, который вернет функция после вызова будет выглядет так:
 * [
 *  {xAxisName: 'May-2018',  column1: 10, column1Trend: 10, column2: 5, column2Trend: 4.5},
 *  {xAxisName: 'June-2018', column1: 8,  column1Trend: 10, column2: 4, column2Trend: 5},
 *  {xAxisName: 'July-2018', column1: 12, column1Trend: 10, column2: 6, column2Trend: 5.5},
 *  ...]
 *
 * @param       data            Набор данных.
 * @param       lineKeys        Список линий, для которых нужно рассчитать тренды.
 *
 * @returns     {*}         Набор данных, к которому добавлены тренды.
 */
export const calculateTrends = (data, lineKeys) => {
    const getLine = (lineKey) => data.map((linesPoints, index) => [index, linesPoints[lineKey]]);

    const trendPoints = Object.fromEntries(
        lineKeys.map(lineKey => [lineKey, calculateTrend(getLine(lineKey))])
    );

    const getTrendPoint = (lineKey, index) => {
        const [_, trendPoint] = trendPoints[lineKey][index]; // eslint-disable-line no-unused-vars
        return trendPoint;
    };

    return data.map((linesPoints, index) => {
        const trends = Object.fromEntries(
            lineKeys.map(lineKey => [`${lineKey}Trend`, getTrendPoint(lineKey, index)])
        );

        return {...linesPoints, ...trends};
    });
};

