import React, {useRef} from 'react';
import {ResponsiveContainer} from 'recharts';
import Grid from "@material-ui/core/Grid";
import useComponentSize from "@rehooks/component-size";

const LineChartWrapper = ({children}) => {
    const ref = useRef(null);
    const {width} = useComponentSize(ref);

    return <Grid container ref={ref}>
        <div style={{width: width}}>
            <ResponsiveContainer height={250}>
                {children}
            </ResponsiveContainer>
        </div>
    </Grid>
};

export default LineChartWrapper;
