import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import AdditionalFiles from "./AdditionalFiles";

const useStyles = makeStyles(theme => ({
    card: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        margin: theme.spacing(1),
    },
    text: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
    }
}));

const ReportInterpretations = ({report}) => {
    const classes = useStyles();

    return <Card className={classes.card}>
        <Typography variant='h5' className={classes.text}>Анализ данных</Typography>
        <Grid container direction='row'>
            <AdditionalFiles
                files={report.additionalFiles}
                fileType={'reportInterpretation'}
                emptyText={<Typography className={classes.text}>Нет данных</Typography>}
            />
        </Grid>
    </Card>
};

export default ReportInterpretations;