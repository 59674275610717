import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/styles";
import ActionButton from "./ActionButton";

const useStyles = makeStyles(theme => ({
    approve: {
        backgroundColor: theme.palette.color.green,
        color: '#ffffff',
        '&:hover': {
            backgroundColor: theme.palette.color.darkGreen,
        },
    }
}));

const ApproveButton = ({isLoading, onClick, disabled}) => {
    const classes = useStyles();

    const title = disabled
        ? 'Отчет будет опубликован и доступен для просмотра, без возможности редактирования или удаления.'
        : 'Данные будут сохранены, отчет будет опубликован. Для активации кнопки необходимо скачать и проверить отчет.';

    return <Tooltip title={title}>
        <div>
            <ActionButton
                isLoading={isLoading}
                className={classes.approve}
                onClick={onClick}
                disabled={disabled}
            >
                Опубликовать
            </ActionButton>
        </div>
    </Tooltip>
};

export default ApproveButton;