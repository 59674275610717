export class SavedFile {
    id;
    reportId;
    name;
    type;

    isSaved = true;
    shouldBeDeleted = false;

    constructor({id, reportId, name, type}) {
        this.id = id;
        this.reportId = reportId;
        this.name = name;
        this.type = type;
    }

    delete() {
        this.shouldBeDeleted = true;
    }
}

export class NewFile {
    file;
    type;

    isSaved = false;
    shouldBeDeleted = false;

    constructor(file, type) {
        this.file = file;
        this.type = type;
    }

    get name() {
        return this.file.name;
    }

    delete() {
        this.shouldBeDeleted = true;
    }
}