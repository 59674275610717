import React from 'react';
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    success: {
        color: theme.palette.color.success
    },
    error: {
        color: theme.palette.color.error
    },
    default: {
        color: theme.palette.color.default
    },
}));

 const Status = ({status}) => {
     const classes = useStyles();

     switch (status) {
         case 'APPROVED':
             return <Typography className={classes.success}>Опубликован</Typography>;
         case 'IN_PROGRESS':
             return <Typography className={classes.default}>В работе</Typography>;
         default:
             throw Error(`Unexpected status ${status}`);
     }
 };

 export default Status;