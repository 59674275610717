/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import AccountsReceivable from './AccountsReceivable';
import Balance from './Balance';
import Debts from './Debts';
import EmployeesDebts from './EmployeesDebts';





/**
* The ReportSummary model module.
* @module model/ReportSummary
* @version 1.0.0
*/
export default class ReportSummary {
    /**
    * Constructs a new <code>ReportSummary</code>.
    * Выдержки из отчета
    * @alias module:model/ReportSummary
    * @class
    * @param accountsReceivable {module:model/AccountsReceivable} 
    * @param debts {module:model/Debts} 
    * @param employeesDebts {module:model/EmployeesDebts} 
    * @param balance {module:model/Balance} 
    * @param comment {String} Комментарий к данным
    */

    constructor(accountsReceivable, debts, employeesDebts, balance, comment) {
        

        
        

        this['accountsReceivable'] = accountsReceivable;this['debts'] = debts;this['employeesDebts'] = employeesDebts;this['balance'] = balance;this['comment'] = comment;

        
    }

    /**
    * Constructs a <code>ReportSummary</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ReportSummary} obj Optional instance to populate.
    * @return {module:model/ReportSummary} The populated <code>ReportSummary</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReportSummary();

            
            
            

            if (data.hasOwnProperty('accountsReceivable')) {
                obj['accountsReceivable'] = AccountsReceivable.constructFromObject(data['accountsReceivable']);
            }
            if (data.hasOwnProperty('debts')) {
                obj['debts'] = Debts.constructFromObject(data['debts']);
            }
            if (data.hasOwnProperty('employeesDebts')) {
                obj['employeesDebts'] = EmployeesDebts.constructFromObject(data['employeesDebts']);
            }
            if (data.hasOwnProperty('balance')) {
                obj['balance'] = Balance.constructFromObject(data['balance']);
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {module:model/AccountsReceivable} accountsReceivable
    */
    accountsReceivable = undefined;
    /**
    * @member {module:model/Debts} debts
    */
    debts = undefined;
    /**
    * @member {module:model/EmployeesDebts} employeesDebts
    */
    employeesDebts = undefined;
    /**
    * @member {module:model/Balance} balance
    */
    balance = undefined;
    /**
    * Комментарий к данным
    * @member {String} comment
    */
    comment = undefined;








}


