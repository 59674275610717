import React from 'react';
import {Router, Switch} from 'react-router-dom';
import Route from './Route';
import Main from './components/main';
import ReportCreator from './components/reportCreator';
import Login from './components/login';
import History from './components/reportsHistory';
import Reports from './components/reportsApproved';
import ReportEditor from './components/reportEditor';
import ReportViewer from './components/reportViewer';
import {history} from './helpers/history';
import {toLogin} from './helpers/go';
import {useAuthContext} from './hooks/useAuthentication';

const App = () => {
    const {hasRole, isLoggedIn} = useAuthContext();

    if (!isLoggedIn() && window.location !== '/login') {
        toLogin();
    }

    return <Router history={history}>
        <Switch>
            {(hasRole('ROLE_ACCOUNTANT') || hasRole('ROLE_ADMIN')) && [
                <Route exact path='/creator' component={ReportCreator} key='creator'/>,
                <Route exact path='/creator/edit/:reportId' component={ReportEditor} key='creator-edit'/>,
                <Route exact path='/history' component={History} key='history'/>,
                <Route exact path='/reports/edit/:reportId' component={ReportEditor} key='reports-edit'/>,
            ]}
            <Route exact path='/reports' component={Reports} key='reports'/>,
            <Route exact path='/reports/view/:reportId' component={ReportViewer} key='reports-view'/>,
            <Route exact path='/' component={Main}/>
            <Route exact path='/login' component={Login}/>
        </Switch>
    </Router>
};

export default App;