import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileUploader from './FileUploader';
import {createSpinningBorder} from '../../../../themes/spinningBorder';

const useStyles = makeStyles(theme => ({
    loading: {
        ...createSpinningBorder(theme)
    }
}));

const LoadingFileUploader = ({onFileSelected, fileName, id}) => {
    const classes = useStyles();

    return <FileUploader onFileSelected={onFileSelected} id={id} cssClass={classes.loading} disabled={true}>
        <Grid item>
            <Typography variant='subtitle2'>Файл заружается...</Typography>
        </Grid>
        <Grid item>
            <Typography variant='subtitle2'>{fileName}</Typography>
        </Grid>
    </FileUploader>
};

export default LoadingFileUploader;