/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ChartsDataNetProfit from './ChartsDataNetProfit';
import ChartsDataRevenue from './ChartsDataRevenue';
import ChartsDataSalary from './ChartsDataSalary';
import PieChartItem from './PieChartItem';





/**
* The ChartsData model module.
* @module model/ChartsData
* @version 1.0.0
*/
export default class ChartsData {
    /**
    * Constructs a new <code>ChartsData</code>.
    * Данные для построения графиков с комментариями
    * @alias module:model/ChartsData
    * @class
    * @param netProfit {Array.<module:model/ChartsDataNetProfit>} Данные для вывода графика чистой прибыли за период
    * @param revenue {Array.<module:model/ChartsDataRevenue>} Данные для вывода графика выручки
    * @param productIncomeForPeriod {Array.<module:model/PieChartItem>} Данные для вывода графика доходов за период
    * @param productIncomeForLastMonth {Array.<module:model/PieChartItem>} Данные для вывода графика доходов за последний месяц
    * @param salary {Array.<module:model/ChartsDataSalary>} Данные для вывода графика зарплаты за период
    * @param expensesForPeriod {Array.<module:model/PieChartItem>} Данные для вывода графика расходов за период
    * @param expensesForLastMonth {Array.<module:model/PieChartItem>} Данные для вывода графика доходов за период
    */

    constructor(netProfit, revenue, productIncomeForPeriod, productIncomeForLastMonth, salary, expensesForPeriod, expensesForLastMonth) {
        

        
        

        this['netProfit'] = netProfit;this['revenue'] = revenue;this['productIncomeForPeriod'] = productIncomeForPeriod;this['productIncomeForLastMonth'] = productIncomeForLastMonth;this['salary'] = salary;this['expensesForPeriod'] = expensesForPeriod;this['expensesForLastMonth'] = expensesForLastMonth;

        
    }

    /**
    * Constructs a <code>ChartsData</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ChartsData} obj Optional instance to populate.
    * @return {module:model/ChartsData} The populated <code>ChartsData</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChartsData();

            
            
            

            if (data.hasOwnProperty('netProfit')) {
                obj['netProfit'] = ApiClient.convertToType(data['netProfit'], [ChartsDataNetProfit]);
            }
            if (data.hasOwnProperty('revenue')) {
                obj['revenue'] = ApiClient.convertToType(data['revenue'], [ChartsDataRevenue]);
            }
            if (data.hasOwnProperty('productIncomeForPeriod')) {
                obj['productIncomeForPeriod'] = ApiClient.convertToType(data['productIncomeForPeriod'], [PieChartItem]);
            }
            if (data.hasOwnProperty('productIncomeForLastMonth')) {
                obj['productIncomeForLastMonth'] = ApiClient.convertToType(data['productIncomeForLastMonth'], [PieChartItem]);
            }
            if (data.hasOwnProperty('salary')) {
                obj['salary'] = ApiClient.convertToType(data['salary'], [ChartsDataSalary]);
            }
            if (data.hasOwnProperty('expensesForPeriod')) {
                obj['expensesForPeriod'] = ApiClient.convertToType(data['expensesForPeriod'], [PieChartItem]);
            }
            if (data.hasOwnProperty('expensesForLastMonth')) {
                obj['expensesForLastMonth'] = ApiClient.convertToType(data['expensesForLastMonth'], [PieChartItem]);
            }
        }
        return obj;
    }

    /**
    * Данные для вывода графика чистой прибыли за период
    * @member {Array.<module:model/ChartsDataNetProfit>} netProfit
    */
    netProfit = undefined;
    /**
    * Данные для вывода графика выручки
    * @member {Array.<module:model/ChartsDataRevenue>} revenue
    */
    revenue = undefined;
    /**
    * Данные для вывода графика доходов за период
    * @member {Array.<module:model/PieChartItem>} productIncomeForPeriod
    */
    productIncomeForPeriod = undefined;
    /**
    * Данные для вывода графика доходов за последний месяц
    * @member {Array.<module:model/PieChartItem>} productIncomeForLastMonth
    */
    productIncomeForLastMonth = undefined;
    /**
    * Данные для вывода графика зарплаты за период
    * @member {Array.<module:model/ChartsDataSalary>} salary
    */
    salary = undefined;
    /**
    * Данные для вывода графика расходов за период
    * @member {Array.<module:model/PieChartItem>} expensesForPeriod
    */
    expensesForPeriod = undefined;
    /**
    * Данные для вывода графика доходов за период
    * @member {Array.<module:model/PieChartItem>} expensesForLastMonth
    */
    expensesForLastMonth = undefined;








}


