import React, {memo} from 'react';
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import NumberInputCell from './NumberInputCell';
import ComputedInputCell from './ComputedInputCell';
import DateInputCell from './DateInputCell';
import BigDecimal from 'js-big-decimal';
import TextWithFiles from "../TextWithFiles";

const sumFloats = (...floats) => {
    return parseFloat(floats
        .map(float => new BigDecimal(float))
        .reduce((sum, decimal) => sum.add(decimal))
        .getValue());
};

const accountsReceivableTotalComputer =
    ({reportSummary: {accountsReceivable: {current, overdue, cashForecast}}}) => {
        return sumFloats(current, overdue, cashForecast);
    };

const debtsTotalComputer =
    ({reportSummary: {debts: {high, middle, low}}}) => {
        return sumFloats(high, middle, low);
    };

const employeesDebtsTotalComputer =
    ({reportSummary: {employeesDebts: {high, middle, low}}}) => sumFloats(high, middle, low);

const balanceTotalComputer =
    ({reportSummary: {balance: {high, middle, low}}}) => {
        return sumFloats(high, middle, low);
    };

const ReportSummary = () => {
    return <Table>
        <TableHead>
            <TableRow>
                <TableCell colSpan={3}>
                    <Typography variant='h6'>Задолженность</Typography>
                    <Typography variant='caption'>На конец периода</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h6'>Остаток</Typography>
                    <Typography variant='caption'>На конец периода</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell>
                    <Typography variant='subtitle1'>Дебиторская (по срокам погашения)</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='subtitle1'>По переведенным ДС (по степени ликвидности)</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='subtitle1'>Исполнителей по займам (по степени ликвидности)</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='subtitle1'>Остаток денежных средств (по степени ликвидности)</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                    <DateInputCell name='reportSummary.accountsReceivable.date' />
                    <DateInputCell name='reportSummary.debts.date'/>
                    <DateInputCell name='reportSummary.employeesDebts.date'/>
                    <DateInputCell name='reportSummary.balance.date'/>
            </TableRow>
            <TableRow>
                <NumberInputCell text='Текущая' name='reportSummary.accountsReceivable.current'/>
                <NumberInputCell text='Высокая' name='reportSummary.debts.high'/>
                <NumberInputCell text='Высокая' name='reportSummary.employeesDebts.high'/>
                <NumberInputCell text='Высокая' name='reportSummary.balance.high'/>
            </TableRow>
            <TableRow>
                <NumberInputCell text='Просроченная' name='reportSummary.accountsReceivable.overdue'/>
                <NumberInputCell text='Средняя' name='reportSummary.debts.middle'/>
                <NumberInputCell text='Средняя' name='reportSummary.employeesDebts.middle'/>
                <NumberInputCell text='Средняя' name='reportSummary.balance.middle'/>
            </TableRow>
            <TableRow>
                <NumberInputCell text='Прогноз ДЗ' name='reportSummary.accountsReceivable.cashForecast'/>
                <NumberInputCell text='Низкая' name='reportSummary.debts.low'/>
                <NumberInputCell text='Низкая' name='reportSummary.employeesDebts.low'/>
                <NumberInputCell text='Низкая' name='reportSummary.balance.low'/>
            </TableRow>
            <TableRow>
                <ComputedInputCell
                    text='ИТОГО'
                    name='reportSummary.accountsReceivable.total'
                    valueComputer={accountsReceivableTotalComputer}
                />
                <ComputedInputCell
                    text='ИТОГО'
                    name='reportSummary.debts.total'
                    valueComputer={debtsTotalComputer}
                />
                <ComputedInputCell
                    text='ИТОГО'
                    name='reportSummary.employeesDebts.total'
                    valueComputer={employeesDebtsTotalComputer}
                />
                <ComputedInputCell
                    text='ИТОГО'
                    name='reportSummary.balance.total'
                    valueComputer={balanceTotalComputer}
                />
            </TableRow>
        </TableBody>
        <TableFooter>
            <TableRow>
                <TableCell colSpan={4}>
                    <TextWithFiles textFieldName={'reportSummary.comment'} fileType={'reportSummary'} />
                </TableCell>
            </TableRow>
        </TableFooter>
    </Table>
};

export default memo(ReportSummary);