import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/styles/makeStyles";
import Report from './Report';
import {useSnackbar} from "../../hooks/useSnackbar";
import Loading from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import {createSpinningBorder} from "../../themes/spinningBorder";
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
    },
    item: {
        padding: theme.spacing(1),
        width: '100%'
    },
    processing: {
        ...createSpinningBorder(theme)
    }
}));

const History = ({reportService}) => {
    const classes = useStyles();
    const {enqueueError} = useSnackbar();

    const [reports, setReports] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isAllReportsLoaded, setAllReportsLoaded] = useState(false);

    useEffect(() => {
        reportService.getReports({amount: 10})
            .then(newReports => {
                if (newReports.length === 0) {
                    setAllReportsLoaded(true);
                }

                setReports(newReports);
            })
            .catch(error => enqueueError(error.body));
    }, [reportService, enqueueError]);

    const loadMore = () => {
        setLoading(true);

        const queryParams = {
            amount: 10,
            oldestReportId: reports[reports.length - 1].id,
        };

        reportService.getReports(queryParams)
            .then(newReports => {
                if (newReports.length === 0) {
                    setAllReportsLoaded(true);
                } else {
                    setReports([...reports, ...newReports]);
                }
            })
            .catch(error => enqueueError(error.body))
            .finally(() => setLoading(false));
    };

    const renderReport = (report) => {
        return <Grid item className={classes.item} key={report.id} xs={12}>
            <Report report={report}/>
            <Divider/>
        </Grid>
    };

    const renderLoadMore = () => {
        return isAllReportsLoaded
            ? <Button size='large' disabled={true}>Отчетов больше нет</Button>
            : <Button
                size='large'
                onClick={loadMore}
                className={clsx(loading && classes.processing)}
                disabled={loading}
            >
                Загрузить еще
            </Button>
    };

    if (reports === null) {
        return <Grid container alignItems='center' className={classes.container} spacing={1}>
            <Grid item>
                <Typography variant='h5'>Отчеты заружаются...</Typography>
            </Grid>
            <Grid item><Loading/></Grid>
        </Grid>
    }

    if (reports.length === 0) {
        return <Grid container alignItems='center' className={classes.container} spacing={1}>
            <Grid item>
                <Typography variant='h5'>Нет данных</Typography>
            </Grid>
        </Grid>
    }

    return <Grid container className={classes.container}>
        {reports.map(renderReport)}
        <Grid item className={classes.item}>
            <Grid container justify='center'>
                <Grid item>
                    {renderLoadMore()}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
};

export default History;