import React, {memo} from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import TextWithFiles from './TextWithFiles';
import Currency from './Currency';
import FileDownloader from '../../common/FileDownloader';
import {downloadReportLink} from "../../../helpers/go";

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    },
    text: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    }
}));


const Header = ({report, setCurrency, currency}) => {
    const classes = useStyles();

    const reportLink = downloadReportLink(report.id);

    return <Grid container direction='row'>
        <Grid item xs={10}>
            <Grid container>
                <Grid item xs={12} className={classes.item}>
                    <TextWithFiles
                        text={report.reportCommentary}
                        label={'Комментарий к отчету'}
                        files={report.additionalFiles}
                        fileType={'reportCommentary'}
                    />
                </Grid>
                <Grid item xs={12} className={classes.item}>
                    <TextWithFiles
                        text={report.introduction}
                        label={'Введение'}
                        files={report.additionalFiles}
                        fileType={'introduction'}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={2}>
            <Grid container direction='column'>
                <Grid item className={classes.item}>
                    <Currency setCurrency={setCurrency} currency={currency} exchangeRateDate={report.dataByCurrency[currency].exchangeRateDate || ''}/>
                </Grid>
                <Grid item className={classes.item}>
                    <FileDownloader link={reportLink} name={report.name} label={'Скачать отчет'}/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
};

export default memo(Header);