import {toLogin, toMain} from '../helpers/go';
import {isEmpty} from 'lodash';

const reject = (message, status) => Promise.reject({type: 'error', body: message, status: status});

const onError = (error) => {
    const {status} = error;
    if (status === 401) {
        console.debug("Unauthorized, going to login");
        toLogin();
        return reject('Вам нужно войти еще раз чтобы продолжить.', error.status);
    } else if (status === 400) {
        console.debug("Неправильный формат данных");
        return reject('Неправильный формат данных', error.status);
    } else if (status === 403) {
        console.debug("Forbidden going to main");
        toMain();
        return reject('У вас нет прав для посещения этой страницы', error.status);
    } else if (status === 422) {
        const message = isEmpty(error.response.body.errorMessage) ? `Ошибка сервера ${status}` : error.response.body.errorMessage;
        console.debug(message);
        return reject(message, error.status);
    } else if (status >= 500) {
        console.log(error);
        return reject(`Ошибка сервера ${status}`, error.status);
    } else {
        console.error(error);
        const data = error.response.text ? JSON.parse(error.response.text) : {};
        const errorMessage = data && data.errorMessage ? data.errorMessage : '';
        return reject(errorMessage);
    }
};

export default onError;
