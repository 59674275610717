/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CurrentPeriodData from './CurrentPeriodData';
import PreviousPeriodData from './PreviousPeriodData';





/**
* The RelationToPreviousPeriods model module.
* @module model/RelationToPreviousPeriods
* @version 1.0.0
*/
export default class RelationToPreviousPeriods {
    /**
    * Constructs a new <code>RelationToPreviousPeriods</code>.
    * Данные для таблицы отношения к предшествующим периодам
    * @alias module:model/RelationToPreviousPeriods
    * @class
    * @param currentPeriod {module:model/CurrentPeriodData} 
    * @param relativelyOneMonth {module:model/PreviousPeriodData} 
    * @param relativelyThreeMonth {module:model/PreviousPeriodData} 
    * @param relativelyHalfYear {module:model/PreviousPeriodData} 
    * @param relativelyOneYear {module:model/PreviousPeriodData} 
    */

    constructor(currentPeriod, relativelyOneMonth, relativelyThreeMonth, relativelyHalfYear, relativelyOneYear) {
        

        
        

        this['currentPeriod'] = currentPeriod;this['relativelyOneMonth'] = relativelyOneMonth;this['relativelyThreeMonth'] = relativelyThreeMonth;this['relativelyHalfYear'] = relativelyHalfYear;this['relativelyOneYear'] = relativelyOneYear;

        
    }

    /**
    * Constructs a <code>RelationToPreviousPeriods</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/RelationToPreviousPeriods} obj Optional instance to populate.
    * @return {module:model/RelationToPreviousPeriods} The populated <code>RelationToPreviousPeriods</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RelationToPreviousPeriods();

            
            
            

            if (data.hasOwnProperty('currentPeriod')) {
                obj['currentPeriod'] = CurrentPeriodData.constructFromObject(data['currentPeriod']);
            }
            if (data.hasOwnProperty('relativelyOneMonth')) {
                obj['relativelyOneMonth'] = PreviousPeriodData.constructFromObject(data['relativelyOneMonth']);
            }
            if (data.hasOwnProperty('relativelyThreeMonth')) {
                obj['relativelyThreeMonth'] = PreviousPeriodData.constructFromObject(data['relativelyThreeMonth']);
            }
            if (data.hasOwnProperty('relativelyHalfYear')) {
                obj['relativelyHalfYear'] = PreviousPeriodData.constructFromObject(data['relativelyHalfYear']);
            }
            if (data.hasOwnProperty('relativelyOneYear')) {
                obj['relativelyOneYear'] = PreviousPeriodData.constructFromObject(data['relativelyOneYear']);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/CurrentPeriodData} currentPeriod
    */
    currentPeriod = undefined;
    /**
    * @member {module:model/PreviousPeriodData} relativelyOneMonth
    */
    relativelyOneMonth = undefined;
    /**
    * @member {module:model/PreviousPeriodData} relativelyThreeMonth
    */
    relativelyThreeMonth = undefined;
    /**
    * @member {module:model/PreviousPeriodData} relativelyHalfYear
    */
    relativelyHalfYear = undefined;
    /**
    * @member {module:model/PreviousPeriodData} relativelyOneYear
    */
    relativelyOneYear = undefined;








}


