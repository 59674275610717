import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import {downloadAdditionalFileLink} from "../../../helpers/go";

const useStyles = makeStyles(theme => ({
    fileIcon: {
        color: theme.palette.color.darkBlue,
        fontSize: '3em'
    },
    row: {
        textAlign: 'center',
        padding: theme.spacing(0.5)
    },
    link: {
        textDecoration: 'none',
        width: '100%'
    },
    card: {
        padding: theme.spacing(2),
        height: '100%',
        width: '100%'
    },
}));

const AdditionalFile = ({file}) => {
    const classes = useStyles();
    const link = downloadAdditionalFileLink(file.reportId, file.id);

    return <Card className={classes.card}>
        <Grid container alignItems='center'>
            <Grid item container xs={12} alignItems={'center'}>
                <Grid item xs={3}>
                    <FileIcon className={classes.fileIcon}/>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{file.name}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction='row' justify='center' alignItems={'center'}>
                    <a href={link} className={classes.link} download={file.name}
                       id='download-report'>
                        <Button color='primary' variant='outlined' fullWidth>Скачать</Button>
                    </a>
                </Grid>
            </Grid>
        </Grid>
    </Card>
};

export default AdditionalFile;