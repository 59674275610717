import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Card from "@material-ui/core/Card";
import {downloadAdditionalFileLink} from "../../../../helpers/go";

const useStyles = makeStyles(theme => ({
    card: {
        '&:hover': {
            backgroundColor: theme.palette.background.hover,
            cursor: 'pointer',
        },
        width: '100%',
        padding: theme.spacing(1),
    },
    link: {
        textDecoration: 'none',
        width: '100%'
    },
    fileIcon: {
        color: theme.palette.color.darkBlue,
        paddingRight: theme.spacing(0.5)
    },
    textLink: {
        color: theme.palette.color.darkBlue,
        textDecoration: 'underline'
    },
}));

const AdditionalFile = ({file}) => {
    const classes = useStyles();
    const link = downloadAdditionalFileLink(file.reportId, file.id);

    return <a href={link} className={classes.link} download={file.name} id='download-report'>
        <Card className={classes.card}>
            <Grid container alignItems={'center'}>
                <Grid item>
                    <FileIcon className={classes.fileIcon} fontSize='large'/>
                </Grid>
                <Grid item>
                    <Typography className={classes.textLink}>{file.name}</Typography>
                </Grid>
            </Grid>
        </Card>
    </a>
};

export default AdditionalFile;