import React, { useState, useContext, createContext } from "react";
import AuthenticationApi from "../generated/api/AuthenticationApi";
import onError from '../helpers/errorHandler';

/**
 * Из этого файла экспортируются Provider "AuthenticationProvider" и hook "useAuthContext" для работы с аутентификацией.
 *
 * Идея в том, что Provider позволяет получить в любом компоненте,
 * который ниже него в дереве компонентов, те данные, которые положили в его context.
 * А хук useContext позволяет получить данные из context для Provider в js-коде компонента, а не в jsx-разметке.
 *
 * Все компоненты, которые используют хук, будут перерендерены при изменении данных в context.
 */

const USER_KEY = "user";

const authenticationApi = new AuthenticationApi();
const authenticationContext = createContext();

/**
 * Провайдер данных и функций для аутентификации.
 * Должен быть в дереве компонентов выше, чем любое использование хука useAuthContext.
 *
 * @example
 *
 * <AuthenticationProvider>
 *     <App />
 * </AuthenticationProvider>
 *
 * @param       children        Дочерние компоненты React.
 */
export function AuthenticationProvider({ children }) {
    const auth = useAuth();
    return <authenticationContext.Provider value={auth}>{children}</authenticationContext.Provider>;
}

/**
 * Хук, возвращает данные и функции для аутентификации.
 *
 * Вызовет ререндер любого компонента, которые использует данный хук после того,
 * как текущий пользователь поменяется (в результате логина или логаута).
 *
 * Поскольку данные берутся из провайдера <AuthenticationProvider>, вызов этого хука должен быть
 * глубже <AuthenticationProvider> в дереве компонентов.
 *
 * Возвращает объект со следующими ключами:
 * * user       - текущий пользователь, если аутентификация не выполнена - null
 * * logIn      - функция, которая принимает два параметра, login и password,
 *                обращается к серверу для проверки логина и пароля,
 *                возвращает promise resolved, если login и password правильные,
 *                promise rejected - если нет
 * * logOut     - функция, которая удаляет данные аутентификации для текущего пользователя,
 *                возвращает promise resolved, если это получилось, promise rejected - если нет
 * * isLoggedIn - функция, которая возвращает true, если аутентификация выполнена, false - если нет
 * * hasRole    - функция, принимает роль пользователя как параметр,
 *                возвращает true, если у пользователя есть такая роль,
 *                false - если нет
 *
 * @see Login.jsx, App.jsx, Routing.jsx, Navigation.jsx
 *
 * @returns     {user, logIn, logOut, isLoggedIn, hasRole}
 */
export const useAuthContext = () => {
    return useContext(authenticationContext);
};

const loadUser = () => {
    if (localStorage.hasOwnProperty(USER_KEY)) {
        return JSON.parse(localStorage.getItem(USER_KEY));
    }

    return null;
};

function useAuth() {
    const [user, setUser] = useState(loadUser());

    const saveUser = (user) => {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
        setUser(user);
    };

    const clearUser = () => {
        localStorage.removeItem(USER_KEY);
        setUser(null);
    };

    const logIn = (login, password) => authenticationApi.logIn({login, password})
        .then(saveUser)
        .catch(onError);

    const logOut = () => authenticationApi.logOut()
        .then(clearUser)
        .catch(onError);

    const isLoggedIn = () => user !== null;

    const hasRole = (role) => isLoggedIn() && user.roles.includes(role);

    return {
        user,
        logIn,
        logOut,
        isLoggedIn,
        hasRole
    };
}