import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    card: {
        '&:hover': {
            backgroundColor: theme.palette.background.hover,
            cursor: 'pointer',
        },
        width: '100%',
        padding: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
        width: '100%'
    },
    fileIcon: {
        color: theme.palette.color.darkBlue,
        paddingRight: theme.spacing(0.5)
    },
    textLink: {
        color: theme.palette.color.darkBlue,
        textDecoration: 'underline'
    },
}));

const FileDownloader = ({name, link, label = '', FileIcon}) => {
    const classes = useStyles();

    return <a href={link} className={classes.link} download={name} id='download-report'>
        <Card className={classes.card}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.text}>{label}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <FileIcon className={classes.fileIcon} fontSize='large'/>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.textLink}>{name}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    </a>
};

export default FileDownloader;