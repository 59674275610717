import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import FileSelector from './FileSelector';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    },
}));

const FileSelectors = ({reportService, reportId, setStepPassed}) => {
    const classes = useStyles();

    const [isIncomesUploaded, setIncomesUploaded] = useState(false);
    const [isExpensesUploaded, setExpensesUploaded] = useState(false);
    const [isDividendsUploaded, setDividendsUploaded] = useState(false);
    const [isAdditionalExpensesUploaded, setAdditionalExpensesUploaded] = useState(false);

    const isStepPassed = isIncomesUploaded && isExpensesUploaded && isDividendsUploaded && isAdditionalExpensesUploaded;

    useEffect(() => setStepPassed(isStepPassed), [isStepPassed, setStepPassed]);

    const uploadIncomes   = (file) => reportService.uploadIncomes(file, reportId);
    const uploadExpenses  = (file) => reportService.uploadExpenses(file, reportId);
    const uploadDividends = (file) => reportService.uploadDividends(file, reportId);
    const uploadAdditionalExpenses = (file) => reportService.uploadAdditionalExpenses(file, reportId);

    return <Grid container direction='row'>
        <Grid item xs={3} className={classes.item}>
            <FileSelector onUpload={uploadIncomes} label='Доходы' setStepPassed={setIncomesUploaded}/>
        </Grid>
        <Grid item xs={3} className={classes.item}>
            <FileSelector onUpload={uploadExpenses} label='Расходы' setStepPassed={setExpensesUploaded}/>
        </Grid>
        <Grid item xs={3} className={classes.item}>
            <FileSelector onUpload={uploadDividends} label='Дивиденды' setStepPassed={setDividendsUploaded}/>
        </Grid>
        <Grid item xs={3} className={classes.item}>
            <FileSelector onUpload={uploadAdditionalExpenses} label='ДопАналитика' setStepPassed={setAdditionalExpensesUploaded}/>
        </Grid>
    </Grid>
};

export default FileSelectors;
