/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import Period from './Period';
import SavedFile from './SavedFile';





/**
* The ReportDataForList model module.
* @module model/ReportDataForList
* @version 1.0.0
*/
export default class ReportDataForList {
    /**
    * Constructs a new <code>ReportDataForList</code>.
    * Данные отчета для истории
    * @alias module:model/ReportDataForList
    * @class
    * @param id {Number} Id отчета
    * @param _date {Date} Дата создания отчета
    * @param name {String} Название отчета
    * @param period {module:model/Period} 
    * @param status {module:model/ReportDataForList.StatusEnum} Статус отчета
    * @param reportCommentary {String} Комментарий к отчету
    * @param additionalFiles {Array.<module:model/SavedFile>} Дополнительные файлы для отчета.
    */

    constructor(id, _date, name, period, status, reportCommentary, additionalFiles) {
        

        
        

        this['id'] = id;this['date'] = _date;this['name'] = name;this['period'] = period;this['status'] = status;this['reportCommentary'] = reportCommentary;this['additionalFiles'] = additionalFiles;

        
    }

    /**
    * Constructs a <code>ReportDataForList</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ReportDataForList} obj Optional instance to populate.
    * @return {module:model/ReportDataForList} The populated <code>ReportDataForList</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReportDataForList();

            
            
            

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Date');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('period')) {
                obj['period'] = Period.constructFromObject(data['period']);
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('reportCommentary')) {
                obj['reportCommentary'] = ApiClient.convertToType(data['reportCommentary'], 'String');
            }
            if (data.hasOwnProperty('additionalFiles')) {
                obj['additionalFiles'] = ApiClient.convertToType(data['additionalFiles'], [SavedFile]);
            }
        }
        return obj;
    }

    /**
    * Id отчета
    * @member {Number} id
    */
    id = undefined;
    /**
    * Дата создания отчета
    * @member {Date} date
    */
    date = undefined;
    /**
    * Название отчета
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {module:model/Period} period
    */
    period = undefined;
    /**
    * Статус отчета
    * @member {module:model/ReportDataForList.StatusEnum} status
    */
    status = undefined;
    /**
    * Комментарий к отчету
    * @member {String} reportCommentary
    */
    reportCommentary = undefined;
    /**
    * Дополнительные файлы для отчета.
    * @member {Array.<module:model/SavedFile>} additionalFiles
    */
    additionalFiles = undefined;






    /**
    * Allowed values for the <code>status</code> property.
    * @enum {String}
    * @readonly
    */
    static StatusEnum = {
    
        /**
         * value: "APPROVED"
         * @const
         */
        "APPROVED": "APPROVED",
    
        /**
         * value: "IN_PROGRESS"
         * @const
         */
        "IN_PROGRESS": "IN_PROGRESS"    
    };



}


