import React, {memo, useContext, useRef} from 'react';
import { BarChart, Bar, XAxis, YAxis, LabelList, CartesianGrid } from 'recharts';
import Grid from '@material-ui/core/Grid';
import {orderBy} from 'lodash'
import {calculateDataTotal, calculatePercent, currencyFormatter} from "../chartFunctions";
import useComponentSize from "@rehooks/component-size";
import {CurrencyContext} from "../../../hooks/useCurrency";
import {normalizeProjectName} from "../chartFunctions";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  '@global': {
    '.recharts-text.recharts-label': {
      fill: '#000000'
    }
  }
}));

const Chart = ({data, colorsForNames}) => {
  const ref = useRef(null);
  const {width} = useComponentSize(ref);
  const sortedData = orderBy(data, ['value'], ['desc']);

  useStyles();

  const {symbol} = useContext(CurrencyContext);

  const dataTotal = calculateDataTotal(data);

  const dataWithColors = sortedData.map((item, i) => {
    const percent = calculatePercent(dataTotal, item.value);
    return {
      ...item,
      fill: colorsForNames[item.name],
      normalizedName: normalizeProjectName(item.name),
      percent,
      label: currencyFormatter(item.value, symbol) + "_(" + percent.toFixed(1) + "%)"
    };
  });

  const height = Math.max(500, dataWithColors.length * 26);

  const percentFormatter = (value) => `${value} %`;

  return <Grid container justify='flex-end' ref={ref}>
    <Grid item>
      <BarChart width={width} height={height} data={dataWithColors} margin={{left: 0}}>
        <CartesianGrid vertical={false}/>
        <XAxis dataKey={"normalizedName"} angle={270} interval={0} dy={60} height={120}/>
        <YAxis tickFormatter={percentFormatter}  padding={{ top: 150 }} />
        <Bar dataKey="percent" isAnimationActive={false}>
          <LabelList dataKey={"label"} position="top" dy={-70} angle={270}/>
        </Bar>
      </BarChart>
    </Grid>
  </Grid>
};

export default memo(Chart);
