import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import FileInput from '../../common/input/FileInput';

const useStyles = makeStyles(theme => ({
    uploader: {
        '&:hover': {
            backgroundColor: theme.palette.background.hover,
            cursor: 'pointer',
        },
        border: theme.border.width,
        borderRadius: theme.border.radius,
        padding: theme.spacing(1.5),
        height: '100%',
        borderStyle: 'dashed',
        borderColor: theme.palette.color.grey
    },
}));

const FileUploader = ({name, onChange}) => {
    const classes = useStyles();

    return <FileInput
        onFileSelected={onChange}
        label={name}
        accept='.doc,.docx,.xls,.xlsx,.pdf,text/plain'
    >
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
            className={classes.uploader}
        >
            <Grid item>
                <Add fontSize='large'/>
            </Grid>
            <Grid item>
                <Typography color='primary'>Добавить файл</Typography>
            </Grid>
        </Grid>
    </FileInput>
};

export default FileUploader;