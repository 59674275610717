import React, {useReducer, memo} from "react";
import {DatePicker} from "@material-ui/pickers";

const SET_DATE = 'SET_DATE';
const CLOSE = 'CLOSE';

const MonthYearPicker = ({defaultDate, onChange}) => {
    const reducer = (date, action) => {
        switch (action.type) {
            case SET_DATE:
                return action.date;
            case CLOSE:
                onChange(date);
                return date;
            default:
                throw new Error(`Unknown action ${action.type}`);
        }
    };

    const [date, dispatch] = useReducer(reducer, defaultDate);

    const setDate = (newDate) => dispatch({type: SET_DATE, date: newDate});
    const close = () => dispatch({type: CLOSE});

    return <DatePicker
        variant="inline"
        openTo="year"
        views={["year", "month"]}
        value={date}
        autoOk={true}
        onChange={setDate}
        onClose={close}
    />
};

export default memo(MonthYearPicker);