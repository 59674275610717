import React, {memo} from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Introduction from './Introduction';
import Currency from './Currency';
import ReportDownloader from "./ReportDownloader";
import {downloadReportLink} from "../../../helpers/go";
import ReportCommentary from "./ReportCommentary";

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    },
}));


const Header = ({report, onDownloadedFile}) => {
    const classes = useStyles();

    const reportLink = downloadReportLink(report.id);

    return <Grid container direction='row'>
        <Grid item xs={10}>
            <Grid container direction='row'>
                <Grid item xs={12} className={classes.item}>
                    <ReportCommentary/>
                </Grid>
                <Grid item xs={12} className={classes.item}>
                    <Introduction/>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={2}>
            <Grid container direction='column'>
                <Grid item className={classes.item}>
                    <Currency/>
                </Grid>
                <Grid item className={classes.item}>
                    <ReportDownloader link={reportLink} name={report.name} onDownloadedFile={onDownloadedFile}/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
};

export default memo(Header);