import React from "react";
import MoneyField from './input/MoneyField';

const Money = ({...rest}) => {
    return <MoneyField
        displayType={'text'}
        {...rest}
    />
};

export default Money;