import React, {memo} from 'react';
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {formatDateToMonthYear} from '../../helpers/dateHelper';
import Card from '@material-ui/core/Card';
import {makeStyles} from "@material-ui/styles";
import clsx from 'clsx';
import Money from './Money';
import Percent from './Percent';

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(1),
        padding: theme.spacing(2)
    },
    negative: {
        color: theme.palette.color.red
    },
    border: {
        borderRight: '1px solid lightGrey'
    }
}));

const RelationToPreviousPeriodsTable = ({data: {currentPeriod, relativelyOneMonth, relativelyThreeMonth, relativelyHalfYear, relativelyOneYear}}) => {
    const classes = useStyles();

    const rows = ['revenue', 'expenses', 'netProfit'];
    const titles = {
        revenue: 'Выручка',
        expenses: 'Расход',
        netProfit: 'Прибыль',
    };

    const renderValue = (value, Component) => {
        return <Typography className={clsx(value < 0 && classes.negative)}>
            <Component value={value}/>
        </Typography>
    };

    const renderRow = (name) => {
        return <TableRow key={`row-${name}`}>
            <TableCell>
                <Typography variant='body1'><strong>{titles[name]}</strong></Typography>
            </TableCell>
            <TableCell align='center' className={classes.border}>
                {renderValue(currentPeriod[name], Money)}
            </TableCell>
            <TableCell align='center'>
                {renderValue(relativelyOneMonth[name], Percent)}
            </TableCell>
            <TableCell align='center'>
                {renderValue(relativelyThreeMonth[name], Percent)}
            </TableCell>
            <TableCell align='center'>
                {renderValue(relativelyHalfYear[name], Percent)}
            </TableCell>
            <TableCell align='center'>
                {renderValue(relativelyOneYear[name], Percent)}
            </TableCell>
        </TableRow>
    };

    const renderDividends = () => {
        return <TableRow>
            <TableCell>
                <Typography variant='body1'><strong>Дивиденды</strong></Typography>
            </TableCell>
            <TableCell align='center' className={classes.border}>
                {renderValue(currentPeriod.dividends, Money)}
            </TableCell>
            <TableCell align='center'>-</TableCell>
            <TableCell align='center'>-</TableCell>
            <TableCell align='center'>-</TableCell>
            <TableCell align='center'>-</TableCell>
        </TableRow>
    };


    return <Card className={classes.card}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2} className={classes.border}>
                        <Typography variant='h6'><strong>Текущий период</strong></Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                        <Typography variant='h6'><strong>Отношение к предшествующим периодам</strong></Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell/>
                    <TableCell align='center' className={classes.border}>
                        <Typography><strong>{formatDateToMonthYear(currentPeriod.date)}</strong></Typography>
                    </TableCell>
                    <TableCell align='center'>
                        <Typography><strong>{formatDateToMonthYear(relativelyOneMonth.period.start)}</strong></Typography>
                        <Typography><strong>{formatDateToMonthYear(relativelyOneMonth.period.end)}</strong></Typography>
                        <Typography variant='caption'>-1 месяц</Typography>
                    </TableCell>
                    <TableCell align='center'>
                        <Typography><strong>{formatDateToMonthYear(relativelyThreeMonth.period.start)}</strong></Typography>
                        <Typography><strong>{formatDateToMonthYear(relativelyThreeMonth.period.end)}</strong></Typography>
                        <Typography variant='caption'>-3 месяца</Typography>
                    </TableCell>
                    <TableCell align='center'>
                        <Typography><strong>{formatDateToMonthYear(relativelyHalfYear.period.start)}</strong></Typography>
                        <Typography><strong>{formatDateToMonthYear(relativelyHalfYear.period.end)}</strong></Typography>
                        <Typography variant='caption'>-6 месяцев</Typography>
                    </TableCell>
                    <TableCell align='center'>
                        <Typography><strong>{formatDateToMonthYear(relativelyOneYear.period.start)}</strong></Typography>
                        <Typography><strong>{formatDateToMonthYear(relativelyOneYear.period.end)}</strong></Typography>
                        <Typography variant='caption'>-12 месяцев</Typography>
                    </TableCell>
                </TableRow>
                {rows.map(renderRow)}
                {renderDividends()}
            </TableBody>
        </Table>
    </Card>
};

export default memo(RelationToPreviousPeriodsTable);