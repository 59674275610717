import React from "react";
import TextField from "@material-ui/core/TextField";
import InputCell from "./InputCell";
import MoneyField from '../../common/input/MoneyField';

const selectAll = (event) => event.target.select();

const NumberInput = ({field: {value, name}, form: {setFieldValue}, ...rest}) => {
    const onValueChanged = ({floatValue}) => setFieldValue(name, floatValue, true);

    return <MoneyField
        name={name}
        value={value}
        onValueChange={onValueChanged}
        customInput={TextField}
        onFocus={selectAll}
        {...rest}
    />
};

const NumberInputCell = ({text, name}) => {
    return <InputCell text={text} component={NumberInput} name={name} />;
};

export default NumberInputCell;