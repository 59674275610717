import {history} from './history';
import ApiClient from '../generated/ApiClient';

let previousPath = '/';

export const toMain = () => {
    history.push("/");
};

export const toReportCreator = () => {
    history.push(`/creator`)
};

export const toReportCreatorEdit = (reportId) => {
    history.push(`/creator/edit/${reportId}`)
};

export const toReports = () => {
    history.push(`/reports`);
};

export const toHistory = () => {
    history.push(`/history`);
};

export const toReportsEdit = (reportId) => {
    history.push(`/reports/edit/${reportId}`);
};

export const toReportsView = reportId => {
    history.push(`/reports/view/${reportId}`);
};

export const toLogin = () => {
    previousPath = history.location.pathname === '/login' ? previousPath : history.location.pathname;
    history.push('/login');
};

export const backFromLogin = () => {
    history.push(previousPath);
};

export const downloadReportLink = (reportId) => {
    return ApiClient.instance.buildUrl(
        `/report/${reportId}/download_report`
    );
};

export const downloadAdditionalFileLink = (reportId, fileId) => {
    return ApiClient.instance.buildUrl(
        `/report/${reportId}/download_additional_file/${fileId}`
    );
};