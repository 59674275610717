import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1),
    },
}));

const Loading = () => {
    const classes = useStyles();

    return <Grid container alignItems='center' className={classes.container} spacing={1}>
        <Grid item>
            <Typography variant='h5'>Отчет заружается...</Typography>
        </Grid>
        <Grid item><CircularProgress/></Grid>
    </Grid>
};

export default Loading;