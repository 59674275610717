import React, {createContext, useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    }
}));

const useAlert = () => {
    const [alert, setAlert] = useState();

    const closeAlert = () => setAlert(null);

    const openAlert = (text, onConfirm) => {
        const confirm = () => {
            closeAlert();
            onConfirm();
        };

        setAlert(<Alert
            handleClose={closeAlert}
            handleConfirm={confirm}
            text={text}
        />)
    };

    return {alert, openAlert, isAlertOpened: Boolean(alert)};
};

const Alert = ({handleClose, handleConfirm, text}) => {
    const classes = useStyles();

    return <Dialog open={true} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <div className={classes.root}>
            <DialogTitle id="alert-dialog-title" >{text}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Нет
                </Button>
                <Button onClick={handleConfirm} color="secondary" autoFocus>
                    Да
                </Button>
            </DialogActions>
        </div>
    </Dialog>
};

const alertContext = createContext();

export function AlertProvider({ children }) {
    const {alert, ...rest} = useAlert();

    return <alertContext.Provider value={rest}>
        {children}
        {alert}
    </alertContext.Provider>;
}

export const useAlertContext = () => {
    return useContext(alertContext);
};