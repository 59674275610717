import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {downloadAdditionalFileLink} from "../../helpers/go";
import FileDownloader from "../common/FileDownloader";

const useStyles = makeStyles(theme => ({
    card: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        margin: theme.spacing(1),
    },
    text: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    item: {
        marginRight: theme.spacing(1)
    }
}));

const AdditionalFiles = ({files, fileType}) => {
    const classes = useStyles();

    const renderFile = (file) => {
        const fileLink = downloadAdditionalFileLink(file.reportId, file.id);

        return <Grid item xs={2} key={file.id} className={classes.item}>
            <FileDownloader link={fileLink} name={file.name}/>
        </Grid>
    };

    return <Grid container direction='row'>
        {files
            .filter(file => file.type === fileType)
            .map(renderFile)
        }
    </Grid>
};

export default AdditionalFiles;