import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Field} from "formik";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    input: {
        width: '150px',
        '& input': {
            textAlign: 'right',
            '-moz-appearance': 'textfield'
        },
        '& input::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
}));

const InputCell = ({text, component: FieldComponent, ...fieldProps}) => {
    const classes = useStyles();

    return <TableCell>
        <Grid container direction='row' justify='space-between' alignItems='center'>
            <Grid item>
                <Typography variant='body2'>{text}</Typography>
            </Grid>
            <Grid item>
                <Field component={FieldComponent} className={classes.input} {...fieldProps} />
            </Grid>
        </Grid>
    </TableCell>
};

export default InputCell;