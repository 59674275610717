import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import FileInput from '../../../common/input/FileInput';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    },
    uploader: {
        '&:hover': {
            backgroundColor: theme.palette.background.hover,
            cursor: 'pointer',
        },
        border: theme.border.width,
        borderRadius: theme.border.radius,
        minHeight: '10em'
    }
}));

const FileUploader = ({onFileSelected, id, cssClass, children, disabled = false}) => {
    const classes = useStyles();

    return <FileInput onFileSelected={onFileSelected} accept='.xlsx' label={id} disabled={disabled}>
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
            className={clsx(cssClass, classes.uploader)}
        >
            {children}
        </Grid>
    </FileInput>
};

export default FileUploader;
