/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


/* eslint-disable */

import ApiClient from "../ApiClient";
import Error from '../model/Error';
import Period from '../model/Period';
import ReportData from '../model/ReportData';
import ReportName from '../model/ReportName';
import ReportUpdate from '../model/ReportUpdate';
import Reports from '../model/Reports';

/**
* Report service.
* @module api/ReportApi
* @version 1.0.0
*/
export default class ReportApi {

    /**
    * Constructs a new ReportApi. 
    * @alias module:api/ReportApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Переводит отчет в статус APPROVED.
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    approveReportWithHttpInfo(reportId) {
      let postBody = null;

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling approveReport");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/approve_report', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Переводит отчет в статус APPROVED.
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    approveReport(reportId) {
      return this.approveReportWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Создает новый пустой отчет и возвращает его id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Number'} and HTTP response
     */
    crateReportDraftWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = 'Number';

      return this.apiClient.callApi(
        '/report/create_report_draft', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Создает новый пустой отчет и возвращает его id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Number'}
     */
    crateReportDraft() {
      return this.crateReportDraftWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Создает отчет на основе загруженных файлов. Переводит отчет в статус IN_PROGRESS.
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportName} and HTTP response
     */
    createReportWithHttpInfo(reportId) {
      let postBody = null;

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling createReport");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = ReportName;

      return this.apiClient.callApi(
        '/report/{reportId}/create_report', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Создает отчет на основе загруженных файлов. Переводит отчет в статус IN_PROGRESS.
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportName}
     */
    createReport(reportId) {
      return this.createReportWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Удаляет дополнительный файл для отчета.
     * @param {Number} fileId Id файла
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteAdditionalFileWithHttpInfo(fileId, reportId) {
      let postBody = null;

      // verify the required parameter 'fileId' is set
      if (fileId === undefined || fileId === null) {
        throw new Error("Missing the required parameter 'fileId' when calling deleteAdditionalFile");
      }

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling deleteAdditionalFile");
      }


      let pathParams = {
        'fileId': fileId,
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/delete_additional_file/{fileId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Удаляет дополнительный файл для отчета.
     * @param {Number} fileId Id файла
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteAdditionalFile(fileId, reportId) {
      return this.deleteAdditionalFileWithHttpInfo(fileId, reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Скачивает дополнительный файл для отчета.
     * @param {Number} reportId Id отчета
     * @param {Number} fileId Id файла
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    downloadAdditionalFileWithHttpInfo(reportId, fileId) {
      let postBody = null;

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling downloadAdditionalFile");
      }

      // verify the required parameter 'fileId' is set
      if (fileId === undefined || fileId === null) {
        throw new Error("Missing the required parameter 'fileId' when calling downloadAdditionalFile");
      }


      let pathParams = {
        'reportId': reportId,
        'fileId': fileId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = File;

      return this.apiClient.callApi(
        '/report/{reportId}/download_additional_file/{fileId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Скачивает дополнительный файл для отчета.
     * @param {Number} reportId Id отчета
     * @param {Number} fileId Id файла
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    downloadAdditionalFile(reportId, fileId) {
      return this.downloadAdditionalFileWithHttpInfo(reportId, fileId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Скачивает отчет
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    downloadReportWithHttpInfo(reportId) {
      let postBody = null;

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling downloadReport");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      let returnType = File;

      return this.apiClient.callApi(
        '/report/{reportId}/download_report', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Скачивает отчет
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    downloadReport(reportId) {
      return this.downloadReportWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Возвращает массив отчетов cо статусом approved, сортированный по периоду. Первыми в списке должны быть отчеты с ближайшим периодом. 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.oldestReportId Id самого старого отчета выведенного на фронтенде
     * @param {Number} opts.amount Количество отчетов
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Reports} and HTTP response
     */
    getApprovedReportsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'oldestReportId': opts['oldestReportId'],
        'amount': opts['amount']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = Reports;

      return this.apiClient.callApi(
        '/report/get_approved_reports', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Возвращает массив отчетов cо статусом approved, сортированный по периоду. Первыми в списке должны быть отчеты с ближайшим периодом. 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.oldestReportId Id самого старого отчета выведенного на фронтенде
     * @param {Number} opts.amount Количество отчетов
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Reports}
     */
    getApprovedReports(opts) {
      return this.getApprovedReportsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Возвращает id последнего утвержденного отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Number'} and HTTP response
     */
    getLastReportIdWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = 'Number';

      return this.apiClient.callApi(
        '/report/get_last_report_id', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Возвращает id последнего утвержденного отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Number'}
     */
    getLastReportId() {
      return this.getLastReportIdWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Возвращает данные отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportData} and HTTP response
     */
    getReportDataWithHttpInfo(reportId) {
      let postBody = null;

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling getReportData");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = ReportData;

      return this.apiClient.callApi(
        '/report/{reportId}/get_report_data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Возвращает данные отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportData}
     */
    getReportData(reportId) {
      return this.getReportDataWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Возвращает массив отчетов со статусом in_progress и approved, сортированный по дате создания. Первыми в списке должны быть отчеты, загруженные последними. 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.oldestReportId Id самого старого отчета выведенного на фронтенде
     * @param {Number} opts.amount Количество отчетов
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Reports} and HTTP response
     */
    getReportsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'oldestReportId': opts['oldestReportId'],
        'amount': opts['amount']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = Reports;

      return this.apiClient.callApi(
        '/report/get_reports', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Возвращает массив отчетов со статусом in_progress и approved, сортированный по дате создания. Первыми в списке должны быть отчеты, загруженные последними. 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.oldestReportId Id самого старого отчета выведенного на фронтенде
     * @param {Number} opts.amount Количество отчетов
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Reports}
     */
    getReports(opts) {
      return this.getReportsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Отклоняет отчет со статусом REJECTED
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    rejectReportWithHttpInfo(reportId) {
      let postBody = null;

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling rejectReport");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/reject_report', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Отклоняет отчет со статусом REJECTED
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    rejectReport(reportId) {
      return this.rejectReportWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Сохраняет данные для отчета.
     * @param {Number} reportId Id отчета
     * @param {module:model/ReportUpdate} reportUpdate Выдержки из отчета и комментарии
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    saveReportWithHttpInfo(reportId, reportUpdate) {
      let postBody = reportUpdate;

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling saveReport");
      }

      // verify the required parameter 'reportUpdate' is set
      if (reportUpdate === undefined || reportUpdate === null) {
        throw new Error("Missing the required parameter 'reportUpdate' when calling saveReport");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/save_report', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Сохраняет данные для отчета.
     * @param {Number} reportId Id отчета
     * @param {module:model/ReportUpdate} reportUpdate Выдержки из отчета и комментарии
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    saveReport(reportId, reportUpdate) {
      return this.saveReportWithHttpInfo(reportId, reportUpdate)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Проверяет и добавляет период для отчета
     * @param {module:model/Period} period 
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    setPeriodWithHttpInfo(period, reportId) {
      let postBody = period;

      // verify the required parameter 'period' is set
      if (period === undefined || period === null) {
        throw new Error("Missing the required parameter 'period' when calling setPeriod");
      }

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling setPeriod");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/set_period', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Проверяет и добавляет период для отчета
     * @param {module:model/Period} period 
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    setPeriod(period, reportId) {
      return this.setPeriodWithHttpInfo(period, reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Загружает файл дополнительных расходов для проверки и обработки
     * @param {File} file Файл отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    uploadAdditionalExpensesWithHttpInfo(file, reportId) {
      let postBody = null;

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadAdditionalExpenses");
      }

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling uploadAdditionalExpenses");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/upload_additional_expenses', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Загружает файл дополнительных расходов для проверки и обработки
     * @param {File} file Файл отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    uploadAdditionalExpenses(file, reportId) {
      return this.uploadAdditionalExpensesWithHttpInfo(file, reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Загружает дополнительный файл для отчета. Дополнительный файл не включается в отчет, но выводится на странице для скачивания. 
     * @param {File} file Файл отчета
     * @param {module:model/String} fileType Id файла
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    uploadAdditionalFileWithHttpInfo(file, fileType, reportId) {
      let postBody = null;

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadAdditionalFile");
      }

      // verify the required parameter 'fileType' is set
      if (fileType === undefined || fileType === null) {
        throw new Error("Missing the required parameter 'fileType' when calling uploadAdditionalFile");
      }

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling uploadAdditionalFile");
      }


      let pathParams = {
        'fileType': fileType,
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/upload_additional_file/{fileType}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Загружает дополнительный файл для отчета. Дополнительный файл не включается в отчет, но выводится на странице для скачивания. 
     * @param {File} file Файл отчета
     * @param {module:model/String} fileType Id файла
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    uploadAdditionalFile(file, fileType, reportId) {
      return this.uploadAdditionalFileWithHttpInfo(file, fileType, reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Загружает файл дивидендов для проверки и обработки
     * @param {File} file Файл отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    uploadDividendsWithHttpInfo(file, reportId) {
      let postBody = null;

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadDividends");
      }

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling uploadDividends");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/upload_dividends', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Загружает файл дивидендов для проверки и обработки
     * @param {File} file Файл отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    uploadDividends(file, reportId) {
      return this.uploadDividendsWithHttpInfo(file, reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Загружает файл расходов для проверки и обработки
     * @param {File} file Файл отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    uploadExpensesWithHttpInfo(file, reportId) {
      let postBody = null;

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadExpenses");
      }

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling uploadExpenses");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/upload_expenses', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Загружает файл расходов для проверки и обработки
     * @param {File} file Файл отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    uploadExpenses(file, reportId) {
      return this.uploadExpensesWithHttpInfo(file, reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Загружает файл расходов для проверки и обработки
     * @param {File} file Файл отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    uploadIncomesWithHttpInfo(file, reportId) {
      let postBody = null;

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadIncomes");
      }

      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling uploadIncomes");
      }


      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json; charset=utf-8'];
      let returnType = null;

      return this.apiClient.callApi(
        '/report/{reportId}/upload_incomes', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Загружает файл расходов для проверки и обработки
     * @param {File} file Файл отчета
     * @param {Number} reportId Id отчета
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    uploadIncomes(file, reportId) {
      return this.uploadIncomesWithHttpInfo(file, reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
