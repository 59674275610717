import React, {memo} from 'react';
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {formatDateToMonthYear} from '../../../helpers/dateHelper';
import Card from '@material-ui/core/Card';
import {makeStyles} from "@material-ui/styles";
import clsx from 'clsx';
import Money from '../../common/Money';
import Percent from '../../common/Percent';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(1),
        padding: theme.spacing(2)
    },
    negative: {
        color: theme.palette.color.red
    },
    table: {
        tableLayout: 'fixed',
        width: '100%'
    },
    item: {
        paddingTop: theme.spacing(2)
    }
}));

const RelationToPreviousPeriodsTable = ({data: {currentPeriod, relativelyOneMonth, relativelyThreeMonth, relativelyHalfYear, relativelyOneYear}}) => {
    const classes = useStyles();

    const rows = ['revenue', 'expenses', 'netProfit', 'dividends'];
    const titles = {
        revenue: 'Выручка',
        expenses: 'Расход',
        netProfit: 'Прибыль',
        dividends: 'Дивиденды'
    };

    const renderValue = (value, Component) => {
        return <Typography className={clsx(value < 0 && classes.negative)}>
            <Component value={value}/>
        </Typography>
    };

    const renderRowForCurrentPeriod = (name) => {
        return <TableRow key={`row-${name}`}>
            <TableCell>
                <Typography variant='body1'><strong>{titles[name]}</strong></Typography>
            </TableCell>
            <TableCell align='center'>
                {renderValue(currentPeriod[name], Money)}
            </TableCell>
        </TableRow>
    };

    const renderRowForPreviousPeriod = (name, value) => {
        return <TableRow>
            <TableCell>
                <Typography variant='body1'><strong>{name}</strong></Typography>
            </TableCell>
            <TableCell align='center'>
                <Percent value={value}/>
            </TableCell>
        </TableRow>
    };

    const renderPreviousPeriod = (period, range) => {
        return <Grid item xs={12}>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell align='center' colSpan={2}>
                            <Typography>
                                <strong>
                                    {`${formatDateToMonthYear(period.period.start)} - ${formatDateToMonthYear(period.period.end)}`}
                                </strong>
                            </Typography>
                            <Typography variant='caption'>{`- ${range}`}</Typography>
                        </TableCell>
                    </TableRow>
                    {renderRowForPreviousPeriod('Доходы', period.revenue)}
                    {renderRowForPreviousPeriod('Расходы', period.expenses)}
                    {renderRowForPreviousPeriod('Прибыль', period.netProfit)}
                </TableBody>
            </Table>
        </Grid>
    };

    return <Card className={classes.card}>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='h6'><strong>Текущий период</strong></Typography>
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell/>
                            <TableCell align='center'>
                                <Typography><strong>{formatDateToMonthYear(currentPeriod.date)}</strong></Typography>
                            </TableCell>
                        </TableRow>
                        {rows.map(renderRowForCurrentPeriod)}
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} className={classes.item}>
                <Typography variant='h6'>
                    <strong>Отношение к предшествующим периодам</strong>
                </Typography>
            </Grid>
            {renderPreviousPeriod(relativelyOneMonth, '1 месяц')}
            {renderPreviousPeriod(relativelyThreeMonth, '3 месяца')}
            {renderPreviousPeriod(relativelyHalfYear, '6 месяцев')}
            {renderPreviousPeriod(relativelyOneYear, '12 месяцев')}
        </Grid>
    </Card>
};

export default memo(RelationToPreviousPeriodsTable);