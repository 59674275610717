import React, {memo} from 'react';
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import NumberCell from './NumberCell';
import TextCell from './TextCell';
import {formatDate} from '../../../../helpers/dateHelper';
import RichTextRenderer from '../../../common/RichTextRenderer';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/styles";
import AdditionalFiles from "../header/AdditionalFiles";

const useStyles = makeStyles(theme => ({
    card: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
    },
    commentary: {
        marginTop: theme.spacing(3),
    },
    header: {
        paddingBottom: theme.spacing(1.5),
    },
    table: {
        paddingBottom: theme.spacing(4)
    },
    bold: {
        fontWeight: 'bold'
    }
}));


const ReportSummary = ({reportSummary, additionalFiles}) => {
    const classes = useStyles();

    return <Card className={classes.card}>
        <Grid container>
            <Grid item xs={12} className={classes.header}>
                <Typography variant='h6' className={classes.bold}>Задолженность</Typography>
                <Typography variant='caption'>На конец периода</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='subtitle1' className={classes.bold}>Дебиторская (по срокам погашения)</Typography>
            </Grid>
            <Grid item xs={12} className={classes.table}>
                <Table>
                    <TableBody>
                        <TextCell name='Дата' value={formatDate(reportSummary.accountsReceivable.date)}/>
                        <NumberCell name='Текущая' value={reportSummary.accountsReceivable.current}/>
                        <NumberCell name='Просроченная' value={reportSummary.accountsReceivable.overdue}/>
                        <NumberCell name='Прогноз ДЗ' value={reportSummary.accountsReceivable.cashForecast}/>
                        <NumberCell name='ИТОГО' value={reportSummary.accountsReceivable.total}/>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='subtitle1' className={classes.bold}>По переведенным ДС (по степени ликвидности)</Typography>
            </Grid>
            <Grid item xs={12} className={classes.table}>
                <Table>
                    <TableBody>
                        <TextCell name='Дата' value={formatDate(reportSummary.debts.date)}/>
                        <NumberCell name='Высокая' value={reportSummary.debts.high}/>
                        <NumberCell name='Средняя' value={reportSummary.debts.middle}/>
                        <NumberCell name='Низкая' value={reportSummary.debts.low}/>
                        <NumberCell name='ИТОГО' value={reportSummary.debts.total}/>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='subtitle1' className={classes.bold}>Исполнителей по займам (по степени ликвидности)</Typography>
            </Grid>
            <Grid item xs={12} className={classes.table}>
                <Table>
                    <TableBody>
                        <TextCell name='Дата' value={formatDate(reportSummary.employeesDebts.date)}/>
                        <NumberCell name='Высокая' value={reportSummary.employeesDebts.high}/>
                        <NumberCell name='Средняя' value={reportSummary.employeesDebts.middle}/>
                        <NumberCell name='Низкая' value={reportSummary.employeesDebts.low}/>
                        <NumberCell name='ИТОГО' value={reportSummary.employeesDebts.total}/>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} className={classes.header}>
                <Typography variant='h6' className={classes.bold}>Остаток</Typography>
                <Typography variant='caption'>На конец периода</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='subtitle1' className={classes.bold}>Остаток денежных средств (по степени ликвидности)</Typography>
            </Grid>
            <Grid item xs={12} className={classes.table}>
                <Table>
                    <TableBody>
                        <TextCell name='Дата' value={formatDate(reportSummary.balance.date)}/>
                        <NumberCell name='Высокая' value={reportSummary.balance.high}/>
                        <NumberCell name='Средняя' value={reportSummary.balance.middle}/>
                        <NumberCell name='Низкая' value={reportSummary.balance.low}/>
                        <NumberCell name='ИТОГО' value={reportSummary.balance.total}/>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} className={classes.commentary}>
                <Typography variant='h6'>Комментарий</Typography>
                <RichTextRenderer text={reportSummary.comment}/>
                <AdditionalFiles files={additionalFiles} fileType={'reportSummary'} />
            </Grid>
        </Grid>
    </Card>
};

export default memo(ReportSummary);