import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertDriveFile';

const useStyles = makeStyles(theme => ({
    card: {
        '&:hover': {
            backgroundColor: theme.palette.background.hover,
            cursor: 'pointer',
        },
        padding: theme.spacing(2),
        height: '100%',
        width: '100%'
    },
    link: {
        textDecoration: 'none',
        width: '100%'
    },
    fileIcon: {
        color: theme.palette.color.darkBlue
    },
    text: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        color: 'black'
    },
    row: {
        textAlign: 'center'
    },
    container: {
        height: '100%',
        width: '100%'
    }
}));

const FileDownloader = ({name, link, label = ''}) => {
    const classes = useStyles();

    return <Grid container className={classes.container}>
        {label && <Grid item>
            <Typography variant='h6' className={classes.text}>{label}</Typography>
        </Grid>
        }
        <a href={link} className={classes.link} download={name} id='download-report'>
            <Card className={classes.card}>
                <Grid container>
                    <Grid item xs={12} className={classes.row}>
                        <FileIcon className={classes.fileIcon} fontSize='large'/>
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <Typography color='primary'>{name}</Typography>
                    </Grid>
                </Grid>
            </Card>
        </a>
    </Grid>
};

export default FileDownloader;