import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: theme.palette.background.hover,
        padding: theme.spacing(2),
        width: '100%'
    },
    fileIcon: {
        color: theme.palette.color.grey,
        paddingRight: theme.spacing(0.5)
    },
    textGrey: {
        color: theme.palette.color.grey,
    },
    row: {
        textAlign: 'center'
    },
}));

const NoFiles = () => {
    const classes = useStyles();

    return <Card className={classes.card}>
        <Grid container alignItems={'center'}>
            <Grid item>
                <FileIcon className={classes.fileIcon} fontSize='large'/>
            </Grid>
            <Grid item>
                <Typography className={classes.textGrey}>Не загружен</Typography>
            </Grid>
        </Grid>
    </Card>
};

export default NoFiles;