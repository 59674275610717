import React from 'react';
import {useLocation} from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {toLogin, toMain, toReports} from "../../../helpers/go";
import {useAuthContext} from '../../../hooks/useAuthentication';
import clsx from 'clsx';
import Exit from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(3),
        fontWeight: 'bold'
    },
    highlighted: {
        color: theme.palette.color.blue
    }
}));

const Navigation = () => {
    const classes = useStyles();

    const {logOut} = useAuthContext();
    const {pathname} = useLocation();

    const isReports = pathname.startsWith('/reports');
    const isMain = !isReports;

    const onLogOut = () => logOut().then(toLogin);

    return <AppBar position='sticky' color='default'>
        <Toolbar>
            <Grid container justify='space-between'>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <Button
                                className={clsx(classes.button, isReports && classes.highlighted)}
                                onClick={toReports}>
                                Отчеты
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={toMain}
                                className={clsx(classes.button, isMain && classes.highlighted)}
                            >
                                Главная
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.button}>
                    <Button onClick={onLogOut}><Exit/></Button>
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>
};

export default Navigation;