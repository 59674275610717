import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import RichTextRenderer from '../../common/RichTextRenderer';
import AdditionalFiles from "./AdditionalFiles";

const useStyles = makeStyles(theme => ({
    card: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        height: '100%',
    },
    text: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
}));

const TextWithFiles = ({text, files, fileType, label}) => {
    const classes = useStyles();

    return <Card className={classes.card}>
        <Typography variant='h5' className={classes.text}>{label}</Typography>
        <RichTextRenderer text={text}/>
        <AdditionalFiles files={files} fileType={fileType} />
    </Card>
};

export default TextWithFiles;