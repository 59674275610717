import React, {useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import {makeStyles} from '@material-ui/styles';
import 'react-quill/dist/quill.snow.css';
import Typography from '@material-ui/core/Typography'
import Switch from '../CustomSwitch';
import RichTextRenderer from '../RichTextRenderer';

const useStyles = makeStyles({
    '@global': {
        '.rich-editor .ql-editor': {
            minHeight: '150px',
            wordBreak: 'break-all'
        },
        '.rich-editor .ql-container': {
            borderRadius: '0 0 5px 5px',
        },
        '.rich-editor .ql-toolbar': {
            borderRadius: '5px 5px 0 0',
        },
    }
});

const MAX_LENGTH = 1500;

const RichTextEditor = ({value, onBlur, onChange, maxLength = MAX_LENGTH}) => {
    const [isPreview, setPreview] = useState(false);
    useStyles();

    const quillRef = useRef(null);
    const getEditor = () => quillRef.current ? quillRef.current.getEditor() : null;

    const handleChange = (newValue) => {
        const editor = getEditor();

        if (editor && editor.getLength() > maxLength) {
            editor.deleteText(maxLength, editor.getLength());
        } else {
            onChange(newValue);
        }
    };

    const handleBlur = (range, source, editor) => onBlur(editor.getText());

    return <div>
        <Switch checked={isPreview} setChecked={setPreview} leftTitle={'Редактирование'} rightTitle={'Предпросмотр'}/>
        {isPreview
            ? <RichTextRenderer text={value}/>
            : <>
                <ReactQuill
                    ref={quillRef}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                    modules={RichTextEditor.modules}
                    className='rich-editor'
                />
                <Typography variant='caption'>{`Не более ${maxLength} символов`}</Typography>
            </>
        }
    </div>
};

const toolbarOptions = [
    [{'header': ['2', '3', false]}],
    ['bold'],
];

RichTextEditor.modules = {
    toolbar: toolbarOptions,
    clipboard: {
        matchVisual: false,
    }
};

const FormikAdapter = ({field: {value, name, onBlur}, form: {setFieldValue}, ...props}) => {
    const handleChange = (newValue) => setFieldValue(name, newValue);

    const handleBlur = (currentValue) => {
        const event = {
            type: 'blur',
            target: {
                type: 'text',
                name: name,
                value: currentValue
            }
        };

        onBlur(event);
    };

    return <RichTextEditor value={value} onChange={handleChange} onBlur={handleBlur} {...props} />;
}

export default FormikAdapter;