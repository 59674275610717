import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(theme => ({
    fileIcon: {
        color: theme.palette.color.darkBlue,
        fontSize: '3em'
    },
    card: {
        padding: theme.spacing(2),
        height: '100%',
        width: '100%'
    },
}));

const UnsavedFile = ({file, remove}) => {
    const classes = useStyles();

    const onDelete = () => remove(file);

    return <Card className={classes.card}>
        <Grid container alignItems='center'>
            <Grid item container xs={12} alignItems={'center'}>
                <Grid item xs={3}>
                    <FileIcon className={classes.fileIcon}/>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{file.name}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button component='span' color='secondary' variant='outlined' fullWidth onClick={onDelete}>
                    Удалить
                </Button>
            </Grid>
        </Grid>
    </Card>
};

export default UnsavedFile;