import {createMuiTheme} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import orange from '@material-ui/core/colors/orange';

export default createMuiTheme({
    palette: {
        primary: lightBlue,
        color: {
            red: red[500],
            darkRed: red[800],
            green: green[400],
            darkGreen: green[800],
            success: green[400],
            warning: orange[400],
            error: red[400],
            default: grey[500],
            grey: grey[500],
            blue: blue[400],
            darkBlue: blue[600],
        },
        background: {
            hover: grey[100],
            success: green[100],
            error: red[100],
        }
    },
    border: {
        width: 2,
        radius: 5
    },
    typography: {useNextVariants: true},
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1em",
            }
        }
    }
});