import React, {memo} from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import {calculateTrends, transformChartData, currencyFormatter, mobileCurrencyFormatter} from "../chartFunctions";
import LineChartWrapper from "./LineChartWrapper";

const RevenueLineChart = ({data, period}) => {

    const transformedData = transformChartData(data, period);
    const dataWithTrends = calculateTrends(transformedData, ['revenue', 'expenses', 'profit']);

    return <LineChartWrapper>
        <LineChart
            data={dataWithTrends}
            margin={{
                top: 5, right: 5, left: 5, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray='3 3'/>
            <XAxis dataKey='name'/>
            <YAxis tickFormatter={mobileCurrencyFormatter} mirror/>
            <Tooltip formatter={currencyFormatter}/>
            <Legend />
            <Line name='Тренд прибыли' dataKey='revenueTrend' stroke='#8ac8ff' dot={false} legendType='none' strokeWidth={4}/>
            <Line name='Тренд расходов' dataKey='expensesTrend' stroke='#ffe499' dot={false} legendType='none' strokeWidth={4}/>
            <Line name='Тренд доходов' dataKey='profitTrend' stroke='#8bff85' dot={false} legendType='none' strokeWidth={4}/>
            <Line name='Прибыль' type='linear' dataKey='revenue' stroke='#0085fa' legendType='square' strokeWidth={3}/>
            <Line name='Расходы' type='linear' dataKey='expenses' stroke='#fcba03' legendType='square' strokeWidth={3}/>
            <Line name='Доходы' type='linear' dataKey='profit' stroke='#0ac400' legendType='square' strokeWidth={3}/>
        </LineChart>
    </LineChartWrapper>
};

export default memo(RevenueLineChart);
