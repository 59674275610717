import React from 'react';
import ReportViewer from './ReportViewer';
import MobileReportViewer from './mobile/ReportViewer';
import Layout from '../common/Layout';
import reportService from '../../services/reportService';
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

export default ({match: {params: {reportId}}}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return <Layout>
        {isMobile
            ? <MobileReportViewer reportService={reportService} reportId={reportId}/>
            : <ReportViewer reportService={reportService} reportId={reportId}/>
        }
    </Layout>
};