import moment from 'moment';
import capitalize from 'lodash/capitalize';

export const formatToIso = (date) => date.format('YYYY-MM-DD');

export const formatDateToIso = (date) => moment(date).format('YYYY-MM-DD');

export const now = () => moment().format('YYYY-MM-DD');

export const endOfPreviousMonth = () => {
    return moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')
};

export const formatDate = (date) => moment(date).format('DD.MM.YYYY');

export const formatDateToMonthYear = date => capitalize(moment(date).format('MMMM YYYY'));

export const formatDateTime = (dateTime) => moment(dateTime).format('DD.MM.YYYY HH:mm');