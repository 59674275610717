import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import AdditionalFiles from "./AdditionalFiles";
import NoFiles from "./NoFiles";

const useStyles = makeStyles(theme => ({
    card: {
        padding: theme.spacing(2),
    },
    text: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
}));

const ReportInterpretations = ({report}) => {
    const classes = useStyles();

    return <Card className={classes.card}>
        <Typography variant='h6' className={classes.text}>Анализ данных</Typography>
        <Grid container direction='column' spacing={1}>
            <AdditionalFiles
                files={report.additionalFiles}
                fileType={'reportInterpretation'}
                emptyText={<NoFiles />}
            />
        </Grid>
    </Card>
};

export default ReportInterpretations;