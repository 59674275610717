import React, {useEffect} from "react";
import InputCell from "./InputCell";
import Money from '../../common/Money';

const ComputedField = ({field: {name}, form: {values, setFieldValue}, valueComputer}) => {
    const value = valueComputer(values);

    useEffect(() => setFieldValue(name, value, false), [name, value, setFieldValue]);

    return <Money
        name={name}
        value={value}
    />;
};

const ComputedInputCell = ({text, name, valueComputer}) => {
    return <InputCell
        text={text}
        component={ComputedField}
        name={name}
        valueComputer={valueComputer}
    />
};

export default ComputedInputCell;