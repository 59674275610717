import React, {useState, useEffect} from 'react';
import {useSnackbar} from "../../hooks/useSnackbar";
import {makeStyles} from '@material-ui/styles';
import {Formik, Form} from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Loading from '../common/Loading';
import Header from './header/Header';
import Charts from '../charts/Charts';
import {formatDateToMonthYear, formatDateToIso} from '../../helpers/dateHelper';
import ReportSummary from './reportSummary/ReportSummary';
import {toMain} from '../../helpers/go';
import {useAlertContext} from '../common/Alert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import RelationToPreviousPeriodsTable from '../common/RelationToPreviousPeriodsTable';
import SaveButton from './buttons/SaveButton';
import ApproveButton from './buttons/ApproveButton';
import RejectButton from './buttons/RejectButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {CurrencyContext} from '../../hooks/useCurrency';
import {SavedFile} from "../../services/Files";
import TextWithFiles from "./TextWithFiles";
import ReportInterpretations from "./ReportInterpretations";

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
    },
    header: {
        padding: theme.spacing(2),
    },
    button: {
        padding: theme.spacing(2),
    },
    "@global": {
        ".block-ui-message-container": {
            position: "fixed"
        }
    }
}));

const convertReportSummaryDates = (reportSummary) => {
    ["accountsReceivable", "debts", "balance", "employeesDebts"].forEach(node =>
        reportSummary[node].date = formatDateToIso(reportSummary[node].date)
    );

    return reportSummary;
};

const doNothing = () => {};

const ReportEditor = ({reportService, reportId}) => {
    const classes = useStyles();
    const {enqueueSuccess, enqueueError} = useSnackbar();

    const [report, setReport] = useState();
    const [allowApprove, setAllowApprove] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [initialData, setInitialData] = useState();

    const {openAlert, isAlertOpened} = useAlertContext();

    const isProcessing = isSaving || isAlertOpened;

    const loadReport = () => {
        reportService.getReportData(reportId)
            .then((savedReport) => {
                setInitialData({
                    additionalFiles: savedReport.additionalFiles.map(file => new SavedFile(file)),
                    chartsCommentary: savedReport.chartsCommentary,
                    introduction: savedReport.introduction,
                    reportSummary: convertReportSummaryDates(savedReport.dataByCurrency.USD.reportSummary),
                    reportCommentary: savedReport.reportCommentary
                });

                setReport(savedReport);
            })
            .catch(error => enqueueError(error.body))
    };

    useEffect(loadReport, [reportService, reportId, enqueueError]);

    const saveReport = (reportUpdate) => () => {
        setSaving(true);

        reportService.saveReport(reportId, reportUpdate)
            .then(() => enqueueSuccess('Отчет успешно сохранен'))
            .then(loadReport)
            .finally(() => setSaving(false))
            .catch(error => {
                enqueueError(error.body);
                setSaving(false);
            });
    };

    const approveReport = (reportUpdate) => {
        setSaving(true);

        reportService.saveReport(reportId, reportUpdate)
            .then(() => reportService.approveReport(reportId))
            .then(() => {
                enqueueSuccess('Отчет успешно утвержден');
                toMain();
            })
            .catch(error => {
                enqueueError(error.body);
                setSaving(false);
            });
    };

    const onApproveClick = (reportUpdate) => () => openAlert(
        <span>Вы уверены, что хотите <strong>опубликовать</strong> отчет? Редактирование и удаление будет невозможно!</span>,
        () => approveReport(reportUpdate)
    );

    const rejectReport = () => {
        setSaving(true);

        reportService.rejectReport(reportId)
            .then(() => {
                enqueueSuccess('Отчет успешно отменен');
                toMain();
            })
            .catch(error => {
                enqueueError(error.body);
                setSaving(false);
            });
    };

    const onRejectClick = () => openAlert(
        <span>Вы уверены, что хотите <strong>удалить</strong> отчет? Отчет будет удален <strong>без возможности восстановления!</strong></span>,
        rejectReport
    );

    const onDownloadedFile = () => setAllowApprove(true);

    const renderChartComment = (chartName) =>
        <TextWithFiles textFieldName={`chartsCommentary.${chartName}`} fileType={chartName} />;

    if (!report) {
        return <Loading/>
    }

    return <CurrencyContext.Provider value={{currency: 'USD', symbol: '$'}}>
        <BlockUi tag='div' blocking={isProcessing} loader={CircularProgress}>
            <Formik
                enableReinitialize={true}
                initialValues={initialData}
                onSubmit={doNothing}
            >
                {({values}) => {
                    return <Form>
                        <Grid container className={classes.container}>
                            <Grid item xs={12} className={classes.header}>
                                <Typography variant='h6'>
                                    Отчет сформирован за
                                    период: {formatDateToMonthYear(report.period.start)} - {formatDateToMonthYear(report.period.end)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Header onDownloadedFile={onDownloadedFile} report={report}/>
                            </Grid>
                            <Grid item xs={12}>
                                <ReportInterpretations />
                            </Grid>
                            <Grid item xs={12}>
                                <RelationToPreviousPeriodsTable
                                    data={report.dataByCurrency.USD.relationToPreviousPeriods}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Charts
                                    chartsData={report.dataByCurrency.USD.chartsData}
                                    period={report.period}
                                    renderComment={renderChartComment}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReportSummary/>
                            </Grid>
                            <Grid item className={classes.item} xs={12}>
                                <Grid container direction='row' justify='center'>
                                    <Grid item className={classes.button}>
                                        <SaveButton isLoading={isProcessing} onClick={saveReport(values)}/>
                                    </Grid>
                                    <Grid item className={classes.button}>
                                        <ApproveButton isLoading={isProcessing} onClick={onApproveClick(values)}
                                                       disabled={!allowApprove}/>
                                    </Grid>
                                    <Grid item className={classes.button}>
                                        <RejectButton isLoading={isProcessing} onClick={onRejectClick}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </BlockUi>
    </CurrencyContext.Provider>
};

export default ReportEditor;