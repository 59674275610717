import React from 'react';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {formatToIso} from '../../../helpers/dateHelper';

const DatePicker = ({field: {value, name}, form: {setFieldValue}, className}) => {

    const onChange = (date) => setFieldValue(name, formatToIso(date), true);

    return <KeyboardDatePicker
        autoOk={true}
        disableToolbar
        variant="inline"
        format="DD.MM.YYYY"
        margin="normal"
        name={name}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{'aria-label': 'change date'}}
        className={className}
    />
};

export default DatePicker;