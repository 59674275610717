import React from 'react';
import RichTextEditor from "../common/input/RichTextEditor";
import {FastField} from "formik";
import Files from "./fileUploader/Files";

const TextWithFiles = ({textFieldName, fileType, ...props}) => {
    return <>
        <FastField component={RichTextEditor} name={textFieldName} {...props} />
        <Files name={'additionalFiles'} fileType={fileType} />
    </>
}

export default TextWithFiles;