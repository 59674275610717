import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedSelect from '../../../common/input/OutlinedSelect';
import {formatDate} from "../../../../helpers/dateHelper";

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    },
    card: {
        padding: theme.spacing(2),
    },
}));

const Currency = ({currency, setCurrency, exchangeRateDate}) => {
    const classes = useStyles();

    const handleChange = (event) => {
        setCurrency(event.target.value)
    };

    return <Card className={classes.card}>
        <Grid container direction='column'>
            <Grid item className={classes.item}>
                <Typography variant='h6'>Валюта</Typography>
            </Grid>
            <Grid item className={classes.item} xs={12}>
                <OutlinedSelect
                    value={currency}
                    onChange={handleChange}
                >
                    <MenuItem value='USD'>USD</MenuItem>
                    <MenuItem value='EUR'>EUR</MenuItem>
                    <MenuItem value='RUB'>RUB</MenuItem>
                </OutlinedSelect>
            </Grid>
            {exchangeRateDate && <Grid item xs={12} align='center'>
                <Typography variant='caption' color='textSecondary'>{`${formatDate(exchangeRateDate)} to USD`}</Typography>
            </Grid>}
        </Grid>
    </Card>
};

export default Currency;