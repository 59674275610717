import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/icons/Input';
import SaveButton from '../common/SaveButton';
import {backFromLogin} from "../../helpers/go";
import {useAuthContext} from '../../hooks/useAuthentication';
import {TextField} from "formik-material-ui";
import {Form, Formik, Field} from "formik";

const useStyles = makeStyles(theme => ({
    login: {
        marginTop: '25vh',
    },
    item: {
        width: '100%',
    },
    card: {
        padding: theme.spacing(4)
    }
}));

const Login = () => {
    const classes = useStyles();
    const {logIn} = useAuthContext();

    const onLogIn = ({login, password}, {setSubmitting}) => {
        setSubmitting(true);

        logIn(login, password)
            .then(() => backFromLogin())
            .catch(() => setSubmitting(false));
    };

    return <Grid container className={classes.login}>
        <Grid item lg={4} sm={2}/>
        <Grid item lg={4} sm={8} xs={12}>
            <Card className={classes.card}>
                <Formik
                    initialValues={{
                        login: '',
                        password: ''
                    }}
                    onSubmit={onLogIn}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <Grid container direction='column' spacing={4}>
                                <Grid item><Typography variant='h3'>Вход</Typography></Grid>
                                <Grid item>
                                    <Field component={TextField}
                                           label='Логин'
                                           name='login'
                                           fullWidth
                                           variant='outlined'
                                           InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type='password'
                                        label='Пароль'
                                        name='password'
                                        fullWidth
                                        variant='outlined'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item>
                                    <SaveButton name='Войти' loading={isSubmitting} Icon={Input}/>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Card>
        </Grid>
        <Grid item lg={4} sm={2}/>
    </Grid>
};

export default Login;