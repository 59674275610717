/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ChartsData from './ChartsData';
import RelationToPreviousPeriods from './RelationToPreviousPeriods';
import ReportSummary from './ReportSummary';





/**
* The CurrencyRelatedData model module.
* @module model/CurrencyRelatedData
* @version 1.0.0
*/
export default class CurrencyRelatedData {
    /**
    * Constructs a new <code>CurrencyRelatedData</code>.
    * Данные зависящие от валюты
    * @alias module:model/CurrencyRelatedData
    * @class
    * @param reportSummary {module:model/ReportSummary} 
    * @param relationToPreviousPeriods {module:model/RelationToPreviousPeriods} 
    * @param chartsData {module:model/ChartsData} 
    */

    constructor(reportSummary, relationToPreviousPeriods, chartsData) {
        

        
        

        this['reportSummary'] = reportSummary;this['relationToPreviousPeriods'] = relationToPreviousPeriods;this['chartsData'] = chartsData;

        
    }

    /**
    * Constructs a <code>CurrencyRelatedData</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CurrencyRelatedData} obj Optional instance to populate.
    * @return {module:model/CurrencyRelatedData} The populated <code>CurrencyRelatedData</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CurrencyRelatedData();

            
            
            

            if (data.hasOwnProperty('reportSummary')) {
                obj['reportSummary'] = ReportSummary.constructFromObject(data['reportSummary']);
            }
            if (data.hasOwnProperty('relationToPreviousPeriods')) {
                obj['relationToPreviousPeriods'] = RelationToPreviousPeriods.constructFromObject(data['relationToPreviousPeriods']);
            }
            if (data.hasOwnProperty('chartsData')) {
                obj['chartsData'] = ChartsData.constructFromObject(data['chartsData']);
            }
            if (data.hasOwnProperty('exchangeRateDate')) {
                obj['exchangeRateDate'] = ApiClient.convertToType(data['exchangeRateDate'], 'Date');
            }
        }
        return obj;
    }

    /**
    * @member {module:model/ReportSummary} reportSummary
    */
    reportSummary = undefined;
    /**
    * @member {module:model/RelationToPreviousPeriods} relationToPreviousPeriods
    */
    relationToPreviousPeriods = undefined;
    /**
    * @member {module:model/ChartsData} chartsData
    */
    chartsData = undefined;
    /**
    * Дата курса валют. Необязательна для USD.
    * @member {Date} exchangeRateDate
    */
    exchangeRateDate = undefined;








}


