import React from 'react';
import Typography from '@material-ui/core/Typography';
import RichTextRenderer from '../../../common/RichTextRenderer';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdditionalFiles from "./AdditionalFiles";
import Grid from "@material-ui/core/Grid";

const ExpandingText = ({text, label, files, fileType, labelVariant = 'h6'}) => {
    return <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls='introduction'
            id='introduction'
        >
            <Typography variant={labelVariant}>{label}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Grid container>
                <Grid item xs={12}>
                    <RichTextRenderer text={text}/>
                </Grid>
                <Grid item xs={12}>
                    <AdditionalFiles files={files} fileType={fileType} />
                </Grid>
            </Grid>
        </ExpansionPanelDetails>
    </ExpansionPanel>
};

export default ExpandingText;