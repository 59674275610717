import React, {memo} from 'react';
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import NumberCell from './NumberCell';
import TextCell from './TextCell';
import {formatDate} from '../../../helpers/dateHelper';
import RichTextRenderer from '../../common/RichTextRenderer';
import AdditionalFiles from "../header/AdditionalFiles";

const ReportSummary = ({reportSummary, additionalFiles}) => {
    return <Table>
        <TableHead>
            <TableRow>
                <TableCell colSpan={3}>
                    <Typography variant='h6'>Задолженность</Typography>
                    <Typography variant='caption'>На конец периода</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h6'>Остаток</Typography>
                    <Typography variant='caption'>На конец периода</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell>
                    <Typography variant='subtitle1'>Дебиторская (по срокам погашения)</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='subtitle1'>По переведенным ДС (по степени ликвидности)</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='subtitle1'>Исполнителей по займам (по степени ликвидности)</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='subtitle1'>Остаток денежных средств (по степени ликвидности)</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TextCell name='Дата' value={formatDate(reportSummary.accountsReceivable.date)} />
                <TextCell name='Дата' value={formatDate(reportSummary.debts.date)} />
                <TextCell name='Дата' value={formatDate(reportSummary.employeesDebts.date)} />
                <TextCell name='Дата' value={formatDate(reportSummary.balance.date)} />
            </TableRow>
            <TableRow>
                <NumberCell name='Текущая' value={reportSummary.accountsReceivable.current} />
                <NumberCell name='Высокая' value={reportSummary.debts.high} />
                <NumberCell name='Высокая' value={reportSummary.employeesDebts.high} />
                <NumberCell name='Высокая' value={reportSummary.balance.high} />
            </TableRow>
            <TableRow>
                <NumberCell name='Просроченная' value={reportSummary.accountsReceivable.overdue} />
                <NumberCell name='Средняя' value={reportSummary.debts.middle} />
                <NumberCell name='Средняя' value={reportSummary.employeesDebts.middle} />
                <NumberCell name='Средняя' value={reportSummary.balance.middle} />
            </TableRow>
            <TableRow>
                <NumberCell name='Прогноз ДЗ' value={reportSummary.accountsReceivable.cashForecast} />
                <NumberCell name='Низкая' value={reportSummary.debts.low} />
                <NumberCell name='Низкая' value={reportSummary.employeesDebts.low} />
                <NumberCell name='Низкая' value={reportSummary.balance.low} />
            </TableRow>
            <TableRow>
                <NumberCell name='ИТОГО' value={reportSummary.accountsReceivable.total} />
                <NumberCell name='ИТОГО' value={reportSummary.debts.total} />
                <NumberCell name='ИТОГО' value={reportSummary.employeesDebts.total} />
                <NumberCell name='ИТОГО' value={reportSummary.balance.total} />
            </TableRow>
        </TableBody>
        <TableFooter>
            <TableRow>
                <TableCell colSpan={4}>
                    <Typography variant='h6'>Комментарий</Typography>
                    <RichTextRenderer text={reportSummary.comment}/>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={4}>
                    <AdditionalFiles files={additionalFiles} fileType={'reportSummary'} />
                </TableCell>
            </TableRow>
        </TableFooter>
    </Table>
};

export default memo(ReportSummary);