import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Typography from '@material-ui/core/Typography';
import FileUploader from './FileUploader';
import {SUCCESS, WARNING, ERROR} from './uploaderStatuses';

const useStyles = makeStyles(theme => ({
    fileIcon: {
        color: theme.palette.color.blue
    },
    [SUCCESS]: {
        borderStyle: 'solid',
        borderColor: theme.palette.color.success,
    },
    [WARNING]: {
        borderStyle: 'solid',
        borderColor: theme.palette.color.warning,
    },
    [ERROR]: {
        borderStyle: 'solid',
        borderColor: theme.palette.color.error,
    }
}));

const FilledFileUploader = ({onFileSelected, id, fileName, status}) => {
    const classes = useStyles();

    return <FileUploader onFileSelected={onFileSelected} id={id} cssClass={classes[status]}>
        <Grid item>
            <FileIcon className={classes.fileIcon} fontSize='large'/>
        </Grid>
        <Grid item>
            <Typography variant='subtitle2'>{fileName}</Typography>
        </Grid>
        <Grid item>
            <Typography color='primary'>Заменить</Typography>
        </Grid>
    </FileUploader>
};

export default FilledFileUploader;