/**
 * Функция возвращает CSS-атрибуты, которые делают из border компонента спиннер.
 *
 * Реализация этого спиннера следующая:
 * 1. Создаем с помощью псевдо-элемента :before слой,
 *    который в 2 раза больше элемента, к которому добавляем спиннер
 *    форма слоя - квадрат
 * 2. С помощью z-index помещаем этот слой под целевой компонент
 * 2. Делим это слой на 4 части вот так:
 *    ---------
 *    | 1 | 2 |
 *    ---------
 *    | 3 | 4 |
 *    ---------
 * 3. Заливаем одну из частей слоя, например 1, контрастным цветом (например синим)
 * 4. Создаем анимацию, которая будет вращать слой на 360 градусов
 * 5. Размещаем слой так, чтобы его центр совпадал с центром целевого компонента.
 *
 * Теперь мы получили спиннер, который вращается под целевым компонентом, но сильно выходит за его края
 * (целевой компонент закрывает центральную часть спиннера своим фоном).
 *
 * Будем отрезать лишнее.
 *
 * 6. Создаем слой с помощью псевдо-элемента :after,
 *    этот слой повторяет форму целевого компонента, но он немного больше
 *    (на нужную нам толщину border) целевого компонента по ширине и высоте.
 *    Это позволяет видеть спиннер в щель между границей целевого компонента
 *    и границей слоя, созданного через :after. Остальная часть спиннера обрезается,
 *    потому что слой :after закончился.
 *
 * @param       theme               Тема Material UI, которая используется в приложении.
 *                                  Из нее берется толщина border, радиус его углов, цвет и т.д.
 * @param       elementWidth        Ширина компонента, к которому нужно добавить border.
 *                                  Стоит передать значение, если компонент настолько широкий,
 *                                  что спиннер при вращении не достает до углов компонента.
 *
 * @returns     CSS-атрибуты для создания спиннера из border компонента.
 */
export const createSpinningBorder = (theme, elementWidth = 400) => ({
    borderColor: 'transparent',
    borderWidth: theme.border.width,
    position: 'relative',
    zIndex: 0,
    overflow: 'hidden',
    '@global': {
        '@keyframes rotate': {
            '100%': {
                transform: 'rotate(1turn)'
            }
        }
    },
    // слой со спиннером
    '&::before': {
        boxSizing: 'border-box',
        content: '""', // кавычки внутри кавычек нужны обязательно
        position: 'absolute',
        zIndex: -2,
        // решение с elementWidth не очень, но в css еще не позволяет сделать calc(max(width, height) * 2)
        // а компоненты могут иметь непредсказуемое сочетание width и height
        width: elementWidth * 2,
        height: elementWidth * 2,
        left: `calc(50% - ${elementWidth}px)`,
        top: `calc(50% - ${elementWidth}px)`,
        backgroundColor: '#bbbbbb',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50% 50%, 50% 50%',
        backgroundPosition: '0 0, 100% 0, 100% 100%, 0 100%',
        backgroundImage: `linear-gradient(${theme.palette.color.blue}, ${theme.palette.color.blue})`,
        animation: '$rotate 2s linear infinite'
    },
    // слой, создающий щель шириной в border, в которую видно спиннер и отсекающий остальную часть спиннера
    '&::after': {
        boxSizing: 'border-box',
        content: '""', // кавычки внутри кавычек нужны обязательно
        position: 'absolute',
        zIndex: -1,
        left: theme.border.width,
        top: theme.border.width,
        width: `calc(100% - ${theme.border.width * 2}px)`,
        height: `calc(100% - ${theme.border.width * 2}px)`,
        background: theme.palette.background.default,
        borderRadius: theme.border.radius - theme.border.width
    }
});