/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ChartsCommentary from './ChartsCommentary';
import DataByCurrency from './DataByCurrency';
import Period from './Period';
import SavedFile from './SavedFile';





/**
* The ReportData model module.
* @module model/ReportData
* @version 1.0.0
*/
export default class ReportData {
    /**
    * Constructs a new <code>ReportData</code>.
    * Данные отчета для отображения
    * @alias module:model/ReportData
    * @class
    * @param id {Number} Id отчета
    * @param name {String} Название отчета
    * @param period {module:model/Period} 
    * @param reportCommentary {String} Комментарий к отчету
    * @param introduction {String} Введение отчета
    * @param chartsCommentary {module:model/ChartsCommentary} 
    * @param dataByCurrency {module:model/DataByCurrency} 
    * @param additionalFiles {Array.<module:model/SavedFile>} Дополнительные файлы для отчета.
    */

    constructor(id, name, period, reportCommentary, introduction, chartsCommentary, dataByCurrency, additionalFiles) {
        

        
        

        this['id'] = id;this['name'] = name;this['period'] = period;this['reportCommentary'] = reportCommentary;this['introduction'] = introduction;this['chartsCommentary'] = chartsCommentary;this['dataByCurrency'] = dataByCurrency;this['additionalFiles'] = additionalFiles;

        
    }

    /**
    * Constructs a <code>ReportData</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ReportData} obj Optional instance to populate.
    * @return {module:model/ReportData} The populated <code>ReportData</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReportData();

            
            
            

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('period')) {
                obj['period'] = Period.constructFromObject(data['period']);
            }
            if (data.hasOwnProperty('reportCommentary')) {
                obj['reportCommentary'] = ApiClient.convertToType(data['reportCommentary'], 'String');
            }
            if (data.hasOwnProperty('introduction')) {
                obj['introduction'] = ApiClient.convertToType(data['introduction'], 'String');
            }
            if (data.hasOwnProperty('chartsCommentary')) {
                obj['chartsCommentary'] = ChartsCommentary.constructFromObject(data['chartsCommentary']);
            }
            if (data.hasOwnProperty('dataByCurrency')) {
                obj['dataByCurrency'] = DataByCurrency.constructFromObject(data['dataByCurrency']);
            }
            if (data.hasOwnProperty('additionalFiles')) {
                obj['additionalFiles'] = ApiClient.convertToType(data['additionalFiles'], [SavedFile]);
            }
        }
        return obj;
    }

    /**
    * Id отчета
    * @member {Number} id
    */
    id = undefined;
    /**
    * Название отчета
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {module:model/Period} period
    */
    period = undefined;
    /**
    * Комментарий к отчету
    * @member {String} reportCommentary
    */
    reportCommentary = undefined;
    /**
    * Введение отчета
    * @member {String} introduction
    */
    introduction = undefined;
    /**
    * @member {module:model/ChartsCommentary} chartsCommentary
    */
    chartsCommentary = undefined;
    /**
    * @member {module:model/DataByCurrency} dataByCurrency
    */
    dataByCurrency = undefined;
    /**
    * Дополнительные файлы для отчета.
    * @member {Array.<module:model/SavedFile>} additionalFiles
    */
    additionalFiles = undefined;








}


