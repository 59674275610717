import React, {memo, useContext} from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts';
import {calculateTrends, transformChartData, currencyFormatter} from "./chartFunctions";
import LineChartWrapper from "./LineChartWrapper";
import {CurrencyContext} from "../../hooks/useCurrency";

const SalaryLineChart = ({data, period}) => {

    const transformedData = transformChartData(data, period);
    const dataWithTrends = calculateTrends(transformedData, ['salary']);

    const {symbol} = useContext(CurrencyContext);
    const formatter = (value) => currencyFormatter(value, symbol);

    return <LineChartWrapper>
        <LineChart
            data={dataWithTrends}
            margin={{
                top: 5, right: 30, left: 40, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray='3 3'/>
            <XAxis dataKey='name'/>
            <YAxis tickFormatter={formatter}/>
            <Tooltip formatter={formatter}/>
            <Line name='Тренд Доп.аналитика' dataKey='salaryTrend' stroke='#8ac8ff' dot={false} legendType='none' strokeWidth={4}/>
            <Line name='Доп.аналитика' type='linear' dataKey='salary' stroke='#0085fa' legendType='square' strokeWidth={3}/>
        </LineChart>
    </LineChartWrapper>
};

export default memo(SalaryLineChart);
