import React, {useEffect, useState, useCallback} from 'react';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FileSelectors from './fileSelectors/FileSelectors';
import PeriodSelector from './periodSelector/PeriodSelector';
import {createSpinningBorder} from '../../themes/spinningBorder';
import {toReportCreatorEdit} from "../../helpers/go";
import {useSnackbar} from "../../hooks/useSnackbar";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    },
    accept: {
        padding: `12px 15px 10px 15px`,
        backgroundColor: theme.palette.color.green,
        color: '#ffffff',
        '&:hover': {
            backgroundColor: theme.palette.color.darkGreen,
        },
    },
    blocker: {
        height: '100vh'
    },
    generating: {
        ...createSpinningBorder(theme)
    }
}));

const ReportCreator = ({reportService}) => {
    const classes = useStyles();
    const { enqueueSuccess, enqueueError } = useSnackbar();

    const [reportId, setReportId] = useState();
    const [areAllFilesCorrect, setAllFilesCorrect] = useState(false);
    const [isPeriodCorrect, setPeriodCorrect] = useState(false);
    const [isGenerating, setGenerating] = useState(false);

    useEffect(() => {
        reportService.createReportDraft()
            .then(id => setReportId(id))
            .catch(error => enqueueError(error.body))
    }, [reportService, enqueueError]);

    const generateReport = () => {
        setGenerating(true);
        reportService.createReport(reportId)
            .then(() => {
                setGenerating(false);
                toReportCreatorEdit(reportId);
                enqueueSuccess('Отчет успешно создан');
            })
            .catch((error) => {
                setGenerating(false);
                enqueueError(error.body);
            });
    };

    const setReportPeriod = useCallback(period => reportService.setPeriod(period, reportId), [reportService, reportId]);

    return <BlockUi tag='div' blocking={isGenerating} className={classes.blocker}>
        <Grid container direction='column'>
            <Grid item className={classes.item}>
                <Grid container direction='row' justify='center'>
                    <Grid item className={classes.item}>
                        <Typography variant='h2'>Генерация отчета</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {(reportId) && <>
                <Divider/>
                <Grid item className={classes.item} container>
                    <Grid container direction='row' justify='center'>
                        <Grid item className={classes.item}>
                            <Typography variant='h4'>1. Период</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justify='center'>
                        <Grid item className={classes.item} xs={6} md={4} lg={3} xl={2}>
                            <PeriodSelector
                                setReportPeriod={setReportPeriod}
                                setStepPassed={setPeriodCorrect}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>}
            {reportId && isPeriodCorrect && <Grid item className={classes.item}>
                <Grid container direction='row' justify='center'>
                    <Grid item className={classes.item}>
                        <Typography variant='h4'>2. Документы</Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='center'>
                    <Grid item md={1} lg={3}/>
                    <Grid item xs={12} md={10} lg={6}>
                        <FileSelectors
                            reportService={reportService}
                            reportId={reportId}
                            setStepPassed={setAllFilesCorrect}
                        />
                    </Grid>
                    <Grid item md={1} lg={3}/>
                </Grid>
            </Grid>}
            {reportId && areAllFilesCorrect && isPeriodCorrect && <>
                <Divider/>
                <Grid className={classes.item}>
                    <Grid container direction='row' justify='center'>
                        <Grid item>
                            <Button
                                onClick={generateReport}
                                disabled={isGenerating}
                                variant='contained'
                                className={clsx(isGenerating && classes.generating, classes.button, classes.accept)}
                            >
                                Сгенерировать
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
        </Grid>
    </BlockUi>
};

export default ReportCreator;
