import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "../../hooks/useSnackbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Loading from "../common/Loading";
import {formatDateToMonthYear} from "../../helpers/dateHelper";
import Header from "./header/Header";
import Charts from "../charts/Charts";
import ReportSummary from "./reportSummary/ReportSummary";
import RichTextRenderer from '../common/RichTextRenderer';
import RelationToPreviousPeriodsTable from '../common/RelationToPreviousPeriodsTable';
import {CurrencyContext, currencySymbols} from '../../hooks/useCurrency';
import AdditionalFiles from './header/AdditionalFiles';
import ReportInterpretations from "./header/ReportInterpretations";

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
    },
    header: {
        padding: theme.spacing(2),
    },
    footer: {
        paddingBottom: theme.spacing(10),
    },
}));

const ReportViewer = ({reportService, reportId}) => {
    const classes = useStyles();
    const {enqueueError} = useSnackbar();

    const [report, setReport] = useState();
    const [currency, setCurrency] = useState('USD');

    useEffect(() => {
        reportService.getReportData(reportId)
            .then(setReport)
            .catch(error => enqueueError(error.body))
    }, [reportService, reportId, enqueueError]);

    const renderChartComment = (chartName) => <>
        <RichTextRenderer text={report.chartsCommentary[chartName]}/>
        <AdditionalFiles files={report.additionalFiles} fileType={chartName} />
    </>

    if (!report) {
        return <Loading/>
    }

    return <CurrencyContext.Provider value={{currency: currency, symbol: currencySymbols[currency]}}>
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.header}>
                <Typography variant='h6'>
                    Отчет сформирован за
                    период: {formatDateToMonthYear(report.period.start)} - {formatDateToMonthYear(report.period.end)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Header report={report} setCurrency={setCurrency} currency={currency}/>
            </Grid>
            <Grid item xs={12}>
                <ReportInterpretations report={report} />
            </Grid>
            <Grid item xs={12}>
                <RelationToPreviousPeriodsTable data={report.dataByCurrency[currency].relationToPreviousPeriods}
                                                currency={currency}/>
            </Grid>
            <Grid item xs={12}>
                <Charts chartsData={report.dataByCurrency[currency].chartsData} period={report.period}
                        renderComment={renderChartComment}/>
            </Grid>
            <Grid item xs={12}>
                <ReportSummary
                    reportSummary={report.dataByCurrency[currency].reportSummary}
                    currency={currency}
                    additionalFiles={report.additionalFiles}
                />
            </Grid>
            <Grid item xs={12} className={classes.footer}/>
        </Grid>
    </CurrencyContext.Provider>
};

export default ReportViewer;