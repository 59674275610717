/**
 * Management accounting reports
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CurrencyRelatedData from './CurrencyRelatedData';





/**
* The DataByCurrency model module.
* @module model/DataByCurrency
* @version 1.0.0
*/
export default class DataByCurrency {
    /**
    * Constructs a new <code>DataByCurrency</code>.
    * @alias module:model/DataByCurrency
    * @class
    * @param USD {module:model/CurrencyRelatedData} 
    * @param EUR {module:model/CurrencyRelatedData} 
    * @param RUB {module:model/CurrencyRelatedData} 
    */

    constructor(USD, EUR, RUB) {
        

        
        

        this['USD'] = USD;this['EUR'] = EUR;this['RUB'] = RUB;

        
    }

    /**
    * Constructs a <code>DataByCurrency</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/DataByCurrency} obj Optional instance to populate.
    * @return {module:model/DataByCurrency} The populated <code>DataByCurrency</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DataByCurrency();

            
            
            

            if (data.hasOwnProperty('USD')) {
                obj['USD'] = CurrencyRelatedData.constructFromObject(data['USD']);
            }
            if (data.hasOwnProperty('EUR')) {
                obj['EUR'] = CurrencyRelatedData.constructFromObject(data['EUR']);
            }
            if (data.hasOwnProperty('RUB')) {
                obj['RUB'] = CurrencyRelatedData.constructFromObject(data['RUB']);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/CurrencyRelatedData} USD
    */
    USD = undefined;
    /**
    * @member {module:model/CurrencyRelatedData} EUR
    */
    EUR = undefined;
    /**
    * @member {module:model/CurrencyRelatedData} RUB
    */
    RUB = undefined;








}


