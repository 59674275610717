import React, {useEffect, useState} from 'react';
import {useSnackbar} from "../../hooks/useSnackbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Loading from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/styles";
import ReportViewer from '../reportViewer/ReportViewer';
import MobileReportViewer from '../reportViewer/mobile/ReportViewer';
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
    },
}));

const Main = ({reportService}) => {
    const {enqueueError} = useSnackbar();
    const classes = useStyles();
    const [reportStatus, setReportStatus] = useState('Отчет заружается...');
    const [isLoading, setLoading] = useState(true);
    const [reportId, setReportId] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        reportService.getLastReportId()
            .then((res) => {
                if (res.response.status === 204) {
                    setReportStatus('Нет данных');
                    setLoading(false);
                } else {
                    const id = res.data;
                    setReportId(id);
                }
            })
            .catch((error) => {
                enqueueError(error.body);
            });
    }, [setReportStatus, enqueueError, reportService]);

    return !reportId ? <Grid container alignItems='center' className={classes.container} spacing={1}>
            <Grid item>
                <Typography variant='h5'>{reportStatus}</Typography>
            </Grid>
            <Grid item>{isLoading && <Loading/>}</Grid>
        </Grid>
        : (isMobile
            ? <MobileReportViewer reportService={reportService} reportId={reportId}/>
            : <ReportViewer reportService={reportService} reportId={reportId}/>)
};

export default Main;