import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    icon: {
        marginRight: '0.5em',
    },
});

const SaveButton = ({isLoading, name, Icon, onClick}) => {
    const classes = useStyles();

    if (isLoading) {
        return <Button color='primary' type='submit' variant='contained' disabled>
            <CircularProgress size={24} className={classes.icon}/>{name}
        </Button>
    } else {
        return <Button color='primary' type='submit' variant='contained' onClick={onClick}>
            <Icon size={24} className={classes.icon}/>{name}
        </Button>
    }
};

export default SaveButton;