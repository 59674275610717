import React, {memo} from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import ExpandingText from './ExpandingText';
import Currency from './Currency';
import FileDownloader from './FileDownloader';
import ReportIcon from '@material-ui/icons/DescriptionOutlined';
import {downloadReportLink} from "../../../../helpers/go";
import ReportInterpretations from "./ReportInterpretations";

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
    },
    text: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    }
}));


const Header = ({report, setCurrency, currency}) => {
    const classes = useStyles();

    const reportLink = downloadReportLink(report.id);

    return <Grid container direction='row'>
        <Grid item xs={12} className={classes.item}>
            <ExpandingText
                text={report.reportCommentary}
                label={'Комментарий к отчету'}
                files={report.additionalFiles}
                fileType={'reportCommentary'}
            />
        </Grid>
        <Grid item xs={12} className={classes.item}>
            <ExpandingText
                text={report.introduction}
                label={'Введение'}
                files={report.additionalFiles}
                fileType={'introduction'}
            />
        </Grid>
        <Grid item xs={12} className={classes.item}>
            <Currency setCurrency={setCurrency} currency={currency} exchangeRateDate={report.dataByCurrency[currency].exchangeRateDate || ''}/>
        </Grid>
        <Grid item xs={12} className={classes.item}>
            <FileDownloader link={reportLink} name={report.name} label={'Отчет'} FileIcon={ReportIcon}/>
        </Grid>
        <Grid item xs={12} className={classes.item}>
            <ReportInterpretations report={report} />
        </Grid>
    </Grid>
};

export default memo(Header);