import React from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/styles/makeStyles";
import {formatDateTime, formatDateToMonthYear} from "../../helpers/dateHelper";
import {toReportsView} from "../../helpers/go";
import {Typography} from "@material-ui/core";
import Description from '@material-ui/icons/DescriptionOutlined';
import File from '@material-ui/icons/InsertDriveFileOutlined';
import RichTextRenderer from "../common/RichTextRenderer";
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandingText from "./ExpandingText";

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: '0.5em',
        color: theme.palette.color.blue,
    },
    card: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.palette.background.hover,
            cursor: 'pointer',
        },
    },
    text: {
        paddingBottom: theme.spacing(1.5),
        fontWeight: 'bold',
    },
    item: {
        padding: theme.spacing(0.5),
    }
}));


const Report = ({report: {id, date, period, reportCommentary}}) => {
    const classes = useStyles();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const goToReportsView = () => toReportsView(id);

    const renderReport = () => {
        return <Card className={classes.card} onClick={goToReportsView}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Typography className={classes.text}>
                                {formatDateToMonthYear(period.start)} - {formatDateToMonthYear(period.end)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.text}>USD</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='space-between' alignItems='center'>
                        <Grid item>
                            <Description className={classes.icon}/>
                            <File className={classes.icon}/>
                        </Grid>
                        <Grid item>
                            <Typography>{formatDateTime(date)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    };

    const renderCommentary = () => {
        return <Card className={classes.card}>
            <RichTextRenderer text={reportCommentary}/>
        </Card>
    };

    const renderSmallCommentary = () => {
        return <ExpandingText text={reportCommentary} label={'Комментарий к отчету'} labelVariant={'body1'}/>
    };

    if (small) {
        return <Grid container>
            <Grid item xs={12} className={classes.item}>{renderReport()}</Grid>
            <Grid item xs={12} className={classes.item}>{renderSmallCommentary()}</Grid>
        </Grid>
    } else {
        return <Grid container>
            <Grid item lg={4} md={5} className={classes.item}>{renderReport()}</Grid>
            <Grid item lg={8} md={7} className={classes.item}>{renderCommentary()}</Grid>
        </Grid>
    }
};

export default Report;