import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import React from "react";

const OutlinedSelect = ({label = '', value, onChange, children}) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return <FormControl variant="outlined" fullWidth>
        <InputLabel ref={inputLabel} id="select-outlined-label">{label}</InputLabel>
        <Select
            labelid="select-outlined-label"
            id="select-outlined"
            value={value}
            onChange={onChange}
            labelwidth={labelWidth}
            variant={'outlined'}
        >
            {children}
        </Select>
    </FormControl>
};

export default OutlinedSelect;
