import ReportApi from '../generated/api/ReportApi';
import onError from '../helpers/errorHandler';

class ReportService {
    constructor(reportApi) {
        this.reportApi = reportApi;
    }

    createReportDraft = () => {
        return this.reportApi.crateReportDraft()
            .catch(onError);
    };

    uploadIncomes = (file, reportId) => {
        // Не нужно делать catch(onError) т.к. он уже выполняется в uploadFile
        return this.uploadFile('uploadIncomes', file, reportId);
    };

    uploadExpenses = (file, reportId) => {
        // Не нужно делать catch(onError) т.к. он уже выполняется в uploadFile
        return this.uploadFile('uploadExpenses', file, reportId);
    };

    uploadDividends = (file, reportId) => {
        // Не нужно делать catch(onError) т.к. он уже выполняется в uploadFile
        return this.uploadFile('uploadDividends', file, reportId);
    };

    uploadAdditionalExpenses = (file, reportId) => {
        return this.uploadFile('uploadAdditionalExpenses', file, reportId);
    };

    createReport = (reportId) => {
        return this.reportApi.createReport(reportId)
            .catch(onError);
    };

    downloadReport = (reportId) => {
        return this.reportApi.downloadReport(reportId)
            .catch(onError);
    };

    updateAdditionalFiles = (reportId, files) => {
        if (!files) {
            return Promise.resolve();
        }

        const toSave = files.filter(file => !file.isSaved && !file.shouldBeDeleted);
        const toDelete = files.filter(file => file.isSaved && file.shouldBeDeleted);

        return Promise.all([
            toSave.map(file => this.reportApi.uploadAdditionalFile(file.file, file.type, reportId)),
            toDelete.map(file => this.reportApi.deleteAdditionalFile(file.id, reportId))
        ])
        .catch(onError);
    }

    saveReport = (reportId, reportUpdate) => {
        const {additionalFiles, ...reportDataUpdate} = reportUpdate;

        return this.updateAdditionalFiles(reportId, additionalFiles)
            .then(() => this.reportApi.saveReport(reportId, reportDataUpdate))
            .catch(onError);
    };

    approveReport = (reportId) => {
        return this.reportApi.approveReport(reportId)
            .catch(onError);
    };

    rejectReport = (reportId) => {
        return this.reportApi.rejectReport(reportId)
            .catch(onError);
    };

    uploadFile = (methodName, file, reportId) => {
        return this.reportApi[methodName](file, reportId)
            .then(() => ({type: 'success', body: 'Файл успешно загружен'}))
            .catch(onError);
    };

    getReports = (queryParams) => {
        return this.reportApi.getReports(queryParams)
            .catch(onError);
    };

    getApprovedReports = (queryParams) => {
        return this.reportApi.getApprovedReports(queryParams)
            .catch(onError);
    };

    getReportData = (id) => {
        return this.reportApi.getReportData(id)
            .catch(onError);
    };

    setPeriod = (period, reportId) => {
        return this.reportApi.setPeriod(period, reportId)
            .catch(onError);
    };

    getLastReportId = () => {
        return this.reportApi.getLastReportIdWithHttpInfo()
            .catch(onError);
    };
}

export default new ReportService(
    new ReportApi()
);