import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    commentary: {
        marginTop: theme.spacing(3),
    },
    chart: {
        padding: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto'
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

const ChartContainer = ({label, period, commentary, children}) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return <Grid item xs={12}>
        <Card className={classes.item}>
            <CardHeader title={label} subheader={period}/>
            <CardContent>{children}</CardContent>
            <CardActions disableSpacing>
                <Button onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        fullWidth
                >
                    Комментарий
                    <ExpandMoreIcon className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}/>
                </Button>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {commentary}
                </CardContent>
            </Collapse>
        </Card>
    </Grid>
};

export default ChartContainer;