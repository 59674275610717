import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import {createSpinningBorder} from "../../../themes/spinningBorder";

const useStyles = makeStyles(theme => ({
    processing: {
        ...createSpinningBorder(theme)
    }
}));

const ActionButton = ({isLoading, className, onClick, disabled = false, children, ...rest}) => {
    const classes = useStyles();
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        // если кнопка не нажата (спиннера нет), то isLoading не должен влиять на спиннер
        if (showSpinner) {
            // сначала isLoading - true, значит спиннер нужно показывать
            // потом isLoading станет false, значит спиннер пора убирать
            setShowSpinner(isLoading);
        }
    }, [isLoading, showSpinner]);

    const clickHandler = () => {
        setShowSpinner(true);
        onClick();
    };

    return <Button
        variant='contained'
        size='large'
        className={clsx(showSpinner && classes.processing, className)}
        disabled={disabled || isLoading}
        onClick={clickHandler}
        {...rest}
    >
        {children}
    </Button>
};

export default ActionButton;