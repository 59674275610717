import React from 'react';
import App from './App';
import theme from "./themes/customTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {AuthenticationProvider} from './hooks/useAuthentication';
import {SnackbarProvider} from './hooks/useSnackbar';
import CssBaseLine from '@material-ui/core/CssBaseline/index';
import moment from 'moment';
import 'moment/locale/ru';
import ApiClient from './generated/ApiClient';
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {AlertProvider} from "./components/common/Alert";

moment.locale('ru');

ApiClient.instance.basePath = process.env.REACT_APP_API_URL;
ApiClient.instance.enableCookies = true;

const Bootstrap = () => {
    return <ThemeProvider theme={theme}>
        <SnackbarProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <AuthenticationProvider>
        <AlertProvider>
            <CssBaseLine/>
            <App/>
        </AlertProvider>
        </AuthenticationProvider>
        </MuiPickersUtilsProvider>
        </SnackbarProvider>
    </ThemeProvider>;
};

export default Bootstrap;